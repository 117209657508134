import { Navigate, Outlet } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { checkSession } from "../store/slices/adminSlices/adminLoginSlice/adminLoginAPI";
import { selectAdminLoading, selectIsValid } from "../store/slices/adminSlices/adminLoginSlice/adminLoginSlice";
import Loading from "./Loading/Loading";

const DriverProtectedRoute = () => {
    const dispatch = useDispatch();
    const loading = useSelector(selectAdminLoading);
    const isValid = useSelector(selectIsValid);
    const [checked, setChecked] = useState(false);

    useEffect(() => {
        const fetchSession = async () => {
            try {
                const session = await dispatch(checkSession()).unwrap();
                setChecked(!!session?.Authorization);
            } catch (error) {
                console.error("Session check failed:", error);
                setChecked(false);
            }
        };

        fetchSession();
    }, [dispatch]);
    const token = localStorage.getItem("accessToken");
    // if (loading || !checked) return <Loading />;
    if (!token) return <Navigate to="/driver/login" replace />;
    return <Outlet />;
};

export default DriverProtectedRoute;
