import './AdminSettings.css';
import { Switch, InputNumber } from 'antd'; // Import Spin for loading state
import React, { useEffect, useState } from 'react';
import {
    selectAdminLogin,
} from "../../../store/slices/adminSlices/adminLoginSlice/adminLoginSlice";
import { useDispatch, useSelector } from "react-redux";
import {
    applySettings, changeAllDriversAccessBonus, changeParkAccessBonus,
    changePassword,
    fetchParkDetails
} from "../../../store/slices/adminSlices/adminLoginSlice/adminLoginAPI";
import { toast } from "react-toastify";

const AdminSettings = () => {
    const adminLogin = useSelector(selectAdminLogin);
    const dispatch = useDispatch();
    const [settings, setSettings] = useState({
        bonus: false,
        transfer_type: false,
        access_bonus: false,
        active_driver: 0,
        super_active_driver: 0,
        daily_limit: 0,
    });
    const [accessBonus, setAccessBonus] = useState(false)
    const [activeCategory, setActiveCategory] = useState('payments');
    const [passwords, setPasswords] = useState({
        username: '',
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
    });
    const [hasChanges, setHasChanges] = useState(false);

    useEffect(() => {
        if (!adminLogin) {
            dispatch(fetchParkDetails());
        }
    }, [dispatch, adminLogin]);
    const updateDetails = async () => {
        const park = await dispatch(fetchParkDetails());
        const data = park.payload[0]
        setAccessBonus(data.access_bonus);
        setSettings({
            transfer_type: data.transfer_type,
            active_driver: data.active_driver,
            super_active_driver: data.super_active_driver,
            daily_limit: data.daily_limit,
        });
    }
    useEffect(() => {
        updateDetails()
    }, []);

    const onChangeSetting = (key, value) => {
        setSettings((prevSettings) => ({
            ...prevSettings,
            [key]: value,
        }));
        setHasChanges(true);
    };

    const handlePasswordChange = (e) => {
        const { name, value } = e.target;
        setPasswords((prev) => ({ ...prev, [name]: value }));
    };

    const handlePasswordSubmit = async () => {
        if (passwords.newPassword !== passwords.confirmPassword) {
            toast.error('Գաղտնաբառերը չեն համընկնում։');
        } else {
            setPasswords({
                ...passwords,
                currentPassword: '',
                newPassword: '',
                confirmPassword: '',
            });
            await dispatch(changePassword({
                username: localStorage.getItem('admin_id'),
                old_password: passwords.currentPassword,
                password: passwords.newPassword
            }));
        }
    };

    const handleSubmitSettings = async () => {
            await dispatch(applySettings(settings)).then(() => {
                setHasChanges(false);
            });
            await dispatch(changeParkAccessBonus(accessBonus))
            dispatch(changeAllDriversAccessBonus(accessBonus))
    };

    return (
        <div className="AdminSettings">
            <div className="AdminSettingsHeader">
                <h2>Կարգավորումներ</h2>

                <div className="AdminSettingsTabs">
                    <button
                        className={`AdminSettingsTab ${activeCategory === 'payments' ? 'active' : ''}`}
                        onClick={() => setActiveCategory('payments')}
                    >
                        Փոխանցումներ
                    </button>
                    <button
                        className={`AdminSettingsTab ${activeCategory === 'security' ? 'active' : ''}`}
                        onClick={() => setActiveCategory('security')}
                    >
                        Անվտանգություն
                    </button>
                </div>

                <div className="AdminSettingsContainer">
                    {/* Security Settings */}
                    {activeCategory === 'security' && (
                        <div className="AdminSettingsItem">
                            <h3>Անվտանգության կարգավորումներ</h3>
                            <div className="AdminSettingsItemContent">
                                <p>Փոփոխել գաղտնաբառը</p>
                            </div>
                            <div className="AdminSettingsItemContent">
                                <form className="ChangePasswordForm">
                                    <label>
                                        Ընթացիկ գաղտնաբառ
                                        <input
                                            type="password"
                                            name="currentPassword"
                                            value={passwords.currentPassword}
                                            onChange={handlePasswordChange}
                                        />
                                    </label>
                                    <label>
                                        Նոր գաղտնաբառ
                                        <input
                                            type="password"
                                            name="newPassword"
                                            value={passwords.newPassword}
                                            onChange={handlePasswordChange}
                                        />
                                    </label>
                                    <label>
                                        Կրկնել նոր գաղտնաբառը
                                        <input
                                            type="password"
                                            name="confirmPassword"
                                            value={passwords.confirmPassword}
                                            onChange={handlePasswordChange}
                                        />
                                    </label>
                                    <button
                                        type="button"
                                        onClick={handlePasswordSubmit}
                                        className="AdminSettingsApplyBtn"
                                    >
                                        Փոխել գաղտնաբառը
                                    </button>
                                </form>
                            </div>
                        </div>
                    )}

                    {/* Payments Settings */}
                    {activeCategory === 'payments' && (
                        <div className="AdminSettingsItem">
                            <h3>Փոխանցումների և բոնուսների կարգավորումներ</h3>
                            <div className="AdminSettingsItemContent">
                                <div className="AdminSettingsLabelQuestion">
                                    <p>Ավտոմատ փոխանցումներ</p>
                                    <span className="tooltip">
                                        <i className="fa-regular fa-circle-question"></i>
                                        <span className="tooltiptext">
                                            Երբ սա միացված է, նոր վարորդները կսահմանվեն ավտոմատ փոխանցման ռեժիմի վրա։
                                        </span>
                                    </span>
                                </div>
                                <Switch
                                    checked={settings.transfer_type}
                                    onChange={(checked) => onChangeSetting('transfer_type', checked)}
                                />
                            </div>
                            <div className="AdminSettingsItemContent">
                                <div className="AdminSettingsLabelQuestion">
                                    <p>Բոնուսների հասանելիությունը</p>
                                    <span className="tooltip">
                                        <i className="fa-regular fa-circle-question"></i>
                                        <span className="tooltiptext">
                                           Երբ սա միացված է, վարորդները կարող են կանխիկացնել իրենց բոնուսները։
                                        </span>
                                    </span>
                                </div>
                                <Switch
                                    checked={accessBonus}
                                    onChange={(checked) => setAccessBonus(checked)}
                                />
                            </div>
                            <div className="AdminSettingsItemContent">
                                <div className="AdminSettingsLabelQuestion">
                                    <p>Ակտիվ վարորդ</p>
                                    <span className="tooltip">
                                        <i className="fa-regular fa-circle-question"></i>
                                        <span className="tooltiptext">
                                           Երբ վարորդը կատարում է ավելի շատ պատվերներ, քան դուք սահմանել եք, նա ակտիվ է և կարող է կանխիկացնել ձեռքով ռեժիմում։
                                        </span>
                                    </span>
                                </div>
                                <InputNumber
                                    value={settings.active_driver}
                                    onChange={(value) => onChangeSetting('active_driver', value)}
                                />
                            </div>
                            <div className="AdminSettingsItemContent">
                                <div className="AdminSettingsLabelQuestion">
                                    <p>Սուպեր ակտիվ վարորդ</p>
                                    <span className="tooltip">
                                        <i className="fa-regular fa-circle-question"></i>
                                        <span className="tooltiptext">
                                          Երբ վարորդը կատարում է ավելի շատ պատվերներ, քան դուք սահմանել եք, նա սուպեր ակտիվ է։
                                        </span>
                                    </span>
                                </div>
                                <InputNumber
                                    value={settings.super_active_driver}
                                    onChange={(value) => onChangeSetting('super_active_driver', value)}
                                />
                            </div>
                            <div className="AdminSettingsItemContent">
                                <div className="AdminSettingsLabelQuestion">
                                    <p>Օրական սահմանաչափ</p>
                                    <span className="tooltip">
                                        <i className="fa-regular fa-circle-question"></i>
                                        <span className="tooltiptext">
                                          Վարորդը չի կարող կանխիկացնել ավելի շատ գումար, քան դուք սահմանել եք 24 ժամվա ընթացքում։
                                        </span>
                                    </span>
                                </div>
                                <InputNumber
                                    value={settings.daily_limit}
                                    onChange={(value) => onChangeSetting('daily_limit', value)}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {activeCategory !== 'security' && (
                <button
                    onClick={handleSubmitSettings}
                    className={`AdminSettingsApplyBtn ${hasChanges ? 'unsaved' : ''}`}
                >
                    Հաստատել
                </button>
            )}
        </div>
    );
};

export default AdminSettings;