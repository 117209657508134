import { createSlice } from "@reduxjs/toolkit";
import {fetchAdminOrderList, fetchSingleOrder, sendOrderTransfer} from "./adminOrderAPI";

const adminOrderList = createSlice({
    name: 'adminOrderList',
    initialState: {
        data: [],
        sendData: null,
        singleOrder: null
    },
    reducers:{},
    extraReducers: (builder) => {
        builder
            .addCase(fetchSingleOrder.fulfilled, (state, {payload}) => {
                return{
                    ...state,
                    singleOrder: payload
                }
            })
            .addCase(fetchAdminOrderList.fulfilled, (state, {payload}) => {
                return {
                    ...state,
                    data: payload
                }
            })
            .addCase(sendOrderTransfer.fulfilled, (state, {payload})=>{
                return {
                    ...state,
                    sendData: {...payload}
                }
            })
    },
});
export const selectSingleOrderList = (state) => state.adminOrderList.singleOrder
export const selectAdminOrderList = (state) => state.adminOrderList.data
export const selectAdminOrderListSendData = (state) => state.adminOrderList.sendData
export const adminOrderListReducer = adminOrderList.reducer;
