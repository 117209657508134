import React, {useEffect, useState} from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import './Sidebar.css';
import Logo from '../../../sharedComponents/Logo';
import { useMediaQuery } from '@mui/material';
import {useDispatch, useSelector} from "react-redux";
import {selectAdminLogin} from "../../../store/slices/adminSlices/adminLoginSlice/adminLoginSlice";
import {fetchParkDetails} from "../../../store/slices/adminSlices/adminLoginSlice/adminLoginAPI";

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showSidebar, setShowSidebar] = useState(false);
  const isSmall = useMediaQuery('(max-width:900px)');
  const dispatch = useDispatch()
  const [has_fraud, setHas_fraud] = useState(false)
  const logoClick = () => {
    navigate('dashboard');
  };

  const handleSidebar = () => {
    if (isSmall) {
      setShowSidebar(!showSidebar);
    }
  };
  const park = async () => {
   const response = await dispatch(fetchParkDetails())
    setHas_fraud(response?.payload[0]?.has_fraud)
  }
  useEffect(() => {
    park()
  },[])
  return (
      <>
        <div className={showSidebar ? 'Sidebar showSidebar' : 'Sidebar'}>
          <div onClick={logoClick} className="SidebarHeading">
            <Logo color="#FF5C00" />
            <h1>Diamond Pay</h1>
          </div>
          <div className="SidebarCategories">
            <h3>Գլխավոր</h3>
            <ul>
              <NavLink
                  to="dashboard"
                  onClick={handleSidebar}
                  className={location.pathname === '/admin' ? 'active Navlink' : 'Navlink'}
              >
                <li><i className="fa-solid fa-chart-line"></i>Հաշվետվություն</li>
              </NavLink>
              <NavLink to="orders" onClick={handleSidebar} className="Navlink">
                <li><i className="fa-solid fa-taxi"></i>Պատվերներ</li>
              </NavLink>
              <NavLink to="transactions" onClick={handleSidebar} className="Navlink">
                <li><i className="fa-solid fa-money-bill-transfer"></i>Գործարքներ</li>
              </NavLink>
              <NavLink to="transfers" onClick={handleSidebar} className="Navlink">
                <li><i className="fa-solid fa-right-left"></i>Փոխանցում</li>
              </NavLink>
              <NavLink to="drivers" onClick={handleSidebar} className="Navlink">
                <li><i className="fa-solid fa-users"></i>Վարորդներ</li>
              </NavLink>
              {
                  (localStorage.getItem('park_id') === "1" || has_fraud) && (
                      <NavLink to="anti-fraud" onClick={handleSidebar} className="Navlink">
                        <li><i className="fas fa-shield-alt"></i>Անվտանգություն</li>
                      </NavLink>
                  )
              }
              <NavLink to="bonus-system" onClick={handleSidebar} className="Navlink">
                <li><i className="fa-solid fa-gift"></i>Բոնուսներ</li>
              </NavLink>
              <NavLink to="contact-us" onClick={handleSidebar} className="Navlink">
                <li><i className="fa-solid fa-phone"></i>Կապ մեզ հետ</li>
              </NavLink>
            </ul>
            <div className="SidebarSettings">
              <NavLink to="settings" onClick={handleSidebar} className="Navlink">
                <span><i className="fa-solid fa-gears"></i>Կարգավորումներ</span>
              </NavLink>
            </div>
          </div>
        </div>
        {showSidebar && <div className="DimmedOverlay" onClick={handleSidebar}></div>}
        {!showSidebar && (
            <button onClick={handleSidebar} className="SidebarShowBtn">
              <i className="fas fa-bars"></i>
            </button>
        )}
      </>
  );
};

export default Sidebar;
