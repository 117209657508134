import { configureStore } from '@reduxjs/toolkit';
import { loginInputReducer } from './slices/loginInputSlice/loginInputSlice';
import { driverReducer } from './slices/deiverSlice/deiverSlice';
import { languageReducer } from './slices/languageSlice/LanguageSlice';
import { driverOrdersReducer } from './slices/driverOrdersSlice/driverOrdersSlice';
import { driverTransferReducer } from './slices/driverTransferSlice/driverTransferSlice';
import { driverListReducer } from './slices/adminSlices/adminDriverList/adminDriverList';
import { adminDashboardReducer } from './slices/adminSlices/adminDashboardSlice/adminDashboardSlice';
import { adminLoginReducer } from './slices/adminSlices/adminLoginSlice/adminLoginSlice';
import { adminTransferReducer } from './slices/adminSlices/adminTransferSlice/adminTransferSlice';
import { adminOrderListReducer } from './slices/adminSlices/adminOrderList/adminOrderList';
import { mainAdminReducer } from './slices/mainAdminSlices/mainAdminLogin/mainAdminSlice';
import {adminTransactionListReducer} from "./slices/adminSlices/adminTransactionList/adminTransactionList";
import {antiFraudReducer} from "./slices/adminSlices/antiFraudSlice/antiFraudSlice";
import {driverDetailsReducer} from "./slices/adminSlices/driverDetailsSlice/driverDetailsSlice";

export const store = configureStore({
  reducer: {
    //--DRIVER--
    loginInput: loginInputReducer,
    driver: driverReducer,
    language: languageReducer,
    driverOrders: driverOrdersReducer,
    driverTransfer: driverTransferReducer,
    //--ADMIN--
    driverList: driverListReducer,
    dashboard: adminDashboardReducer,
    adminLogin: adminLoginReducer,
    adminTransfer: adminTransferReducer,
    adminOrderList: adminOrderListReducer,
    adminTransactionList: adminTransactionListReducer,
    antiFraud: antiFraudReducer,
    driverDetails: driverDetailsReducer,
    //--MAIN_ADMIN--
    mainAdminLogin: mainAdminReducer
  },
});
