import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from '../../axiosInstance';
import API_BASE_URL from "../../../apiRoute";

export const checkDriver = createAsyncThunk(
    'loginInput/checkDriver',
    async function(phone) {
            
            const response = await axios.get(`https://api.diamondpay.am/api/driver/check`, {
                params:{
                    phone
                },
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            
            const data = response.data;
            return data;
        }
);



export const registerDriver = createAsyncThunk(
    'loginInput/registerDriver',
    async function({ phone, password }, { rejectWithValue }) {
        try {
            if (password.length === 0) {
                throw new Error('Password must be longer than 4 characters.');
            }

            const response = await axios.post(`https://api.diamondpay.am/api/driver/register`, {
                phone,
                password
            }, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            const data = response.data;
            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);
export const generateDriverPassword = createAsyncThunk(
    'loginInput/generateDriverPassword',
    async function({phone_number, password}) {

        const response = await axios.post(`${API_BASE_URL}/users/generate_password/`, {
                phone_number,
                password
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                }
            });

        const data = response.data;
        return data;
    }
);
export const sendPassword = createAsyncThunk(
    'loginInput/sendPassword',
    async function({id, password}) {
        
            const response = await axios.post(`${API_BASE_URL}/api/token/`, {
                    id,
                    password,
                    role: 'driver'
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            
            const data = response.data;
            return data;
        }
);
export const logout = createAsyncThunk(
    'loginInput/logout',
    async function () {
        const response = await axios.post(`${API_BASE_URL}/users/logout/`,{
            headers: {
                'Content-Type': 'application/json'
            }
        })
        const data = response.data
        return data
    }
)
