import { useDispatch, useSelector } from 'react-redux';
import { selectLoginInput, setLoginInput } from '../../../store/slices/loginInputSlice/loginInputSlice';
import { checkDriver, generateDriverPassword, sendPassword } from '../../../store/slices/loginInputSlice/loginInputAPI';
import { selectLanguage } from '../../../store/slices/languageSlice/LanguageSlice';
import Logo from '../../../sharedComponents/Logo';
import './Login.css';
import { useState } from 'react';
import { checkSession } from '../../../store/slices/adminSlices/adminLoginSlice/adminLoginAPI';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import {driverParks} from "../../../store/slices/deiverSlice/deiverAPI";

const Login = () => {
    const dispatch = useDispatch();
    const language = useSelector(selectLanguage);
    const [passwordType, setPasswordType] = useState('password');
    const [loginInputs, setLoginInputs] = useState({ phone_number: '+374', password: '' });
    const navigate = useNavigate();
    const activePark = localStorage.getItem('activePark');

    const handlePassVisibility = () => {
        setPasswordType(prev => (prev === 'password' ? 'text' : 'password'));
    };

    const handleLoginInputs = (e) => {
        const { name, value } = e.target;
        setLoginInputs(prev => ({
            ...prev,
            [name]: name === 'phone_number' && !value.startsWith('+374') ? '+374' : value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let driver_id
        await dispatch(generateDriverPassword(loginInputs));
        const parks = await dispatch(driverParks(loginInputs.phone_number))
        if (activePark){
            driver_id = parks?.payload?.driver_ids?.find(el => el.park_id = activePark).id
        }else {
            driver_id = parks?.payload?.driver_ids[0].id
        }
        const login = await dispatch(sendPassword({id: driver_id, password: loginInputs.password}));
        if (login?.payload){
            localStorage.setItem('driverInfo', driver_id);
        }
        const session = await dispatch(checkSession());
        if (session?.payload?.Authorization) {
            navigate('/driver');
        } else {
            toast.error(language === 'arm' ? 'Սխալ հեռախոսահամար կամ ծածկագիր' : language === 'rus' ? 'Неверный номер телефона или пароль' : 'Invalid phone number or password');
        }
    };

    return (
        <div className="Login">
            <div className="diamondLogo">
                <Logo size="20px" color="white" />
                <h1 className="LoginDiamond">Diamond Pay</h1>
            </div>
            <div className="LoginHeader">
                <span className="LoginHeading">
                    {language === 'arm' ? 'Բարի Գալուստ' : language === 'rus' ? 'Добро пожаловать' : 'Welcome'}
                </span>
            </div>
            <div className={language === 'rus' ? 'LoginMain rusLangLoginMain' : 'LoginMain'}>
                <form className="LoginForm" onSubmit={handleSubmit}>
                    <span>{language === 'arm' ? 'Մուտքագրեք ձեր տվյալները' : language === 'rus' ? 'Введите ваши данные' : 'Enter your details'}</span>
                    <input
                        className="LoginFormInput"
                        type="tel"
                        name="phone_number"
                        value={loginInputs.phone_number}
                        onChange={handleLoginInputs}
                        maxLength={12}
                    />
                    <div className="registrationEyeInput">
                        <input
                            className="driverRegisterInput"
                            type={passwordType}
                            name="password"
                            placeholder={language === 'arm' ? 'Ծածկագիր' : language === 'rus' ? 'Пароль' : 'Password'}
                            value={loginInputs.password}
                            onChange={handleLoginInputs}
                            required
                        />
                        <i
                            onClick={handlePassVisibility}
                            className={passwordType === 'password' ? 'far fa-eye driverEye' : 'far fa-eye-slash driverEye'}
                        ></i>
                    </div>
                    <button type="submit" className="loginFormButton">
                        {language === 'arm' ? 'Մուտք' : language === 'rus' ? 'Войти' : 'Log in'}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default Login;