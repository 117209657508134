import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from '../../axiosInstance';
import API_BASE_URL from "../../../apiRoute";

export const fetchDriverOrders = createAsyncThunk(
    'driverOrders/fetchDriverOrders',
    async function (driver_id) {
        const response = await axios.get(`https://api.diamondpay.am/api/driver/order/list`, {
            params: {
                driver_id: driver_id,
                start: '2024-11-01'
            },
            headers: {
                'Content-Type': 'application/json',
            }

        });

        const data = response.data;
        return data;
    }
);

export const fetchDriverTransactions = createAsyncThunk(
    'driverOrders/fetchDriverTransactions',
    async function (driver_id) {
        const accessToken = localStorage.getItem('accessToken'); // Get token from localStorage

        const response = await axios.get(
            `${API_BASE_URL}/utils/transaction/list/?driver_id=${driver_id}&status=completed`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`, // Include token
                },
            }
        );
        console.log(response.data)
        return response.data;

    }
);



