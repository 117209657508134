import React from 'react';
import {BrowserRouter, Routes, Route, Navigate} from 'react-router-dom';
import './App.css';
import AdminApp from './admin/adminApp';
import DriverApp from './driver/driverApp';
import MainAdminApp from './mainAdmin/mainAdminApp';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
    return (
        <div className="App">
          <ToastContainer  pauseOnFocusLoss={false} limit={3}/>
            <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<Navigate to="/driver" replace />} />
                        <Route path="/admin/*" element={<AdminApp />} />
                        <Route path="/mainDiamondAppPay/*" element={<MainAdminApp />} />
                        <Route path="/*" element={<DriverApp />} />
                    </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;
