import {createAsyncThunk} from "@reduxjs/toolkit";
import axios from "../../../axiosInstance";
import API_BASE_URL from "../../../../apiRoute";

export const fetchAntiFraud = createAsyncThunk(
    'antiFraud/fetchAntiFraud',
    async function () {

        const accessToken = localStorage.getItem('accessToken'); // Retrieve access token
        const park = localStorage.getItem('park_id');
        const response = await axios.get(
            `${API_BASE_URL}/utils/faud/list/`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`, // Add access token
                },
            }
        );
        console.log(response.data);
        return response.data;
    }
);

export const updateFraud = createAsyncThunk(
    'antiFraud/updateFraud',
    async function ({id, payload}) {
        const accessToken = localStorage.getItem('accessToken');
        const response = await axios.patch(
            `${API_BASE_URL}/utils/faud/update/${id}/`,
            {...payload},
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`, // Add access token
                },
            }
        );
        console.log(response.data);
        return response.data;
    }
);
export const updateParkFraudSettings = createAsyncThunk(
    'antiFraud/updateParkFraudSettings',
    async function ( payload) {
        const accessToken = localStorage.getItem('accessToken');
        const park = localStorage.getItem('park_id');
        const response = await axios.patch(
            `${API_BASE_URL}/park/park/update/${park}/`,
            {...payload},
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`, // Add access token
                },
            }
        );
        console.log(response.data);
        return response.data;
    }
);
