import { createSlice } from "@reduxjs/toolkit";
import {
    adminList,
    adminLogout,
    changePassword,
    checkIsLoggedIn,
    checkSession, fetchParkDetails,
    register,
    sendAdminPassword
} from "./adminLoginAPI";
import {toast} from "react-toastify";

const adminLoginSlice = createSlice({
    name: 'adminLogin',
    initialState: {
        loading: false,
        data: null,
        passwordError: false,
        isValid: null
    },
    reducers:{
        handleIsValid: (state, action) => {
            return {
                ...state,
                isValid: null,
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(adminList.fulfilled, (state, {payload}) => {
                localStorage.setItem('admin_id', payload[0]?.username)
                localStorage.setItem('park_id', payload[0]?.park_id)
            })
            .addCase(sendAdminPassword.fulfilled, (state, {payload}) => {
                localStorage.setItem('accessToken', payload?.access)
                return {
                    ...state,
                    passwordError: false,
                    loading:false,
                }
            })
            .addCase(sendAdminPassword.pending, (state, {payload}) => {
                return {
                    ...state,
                    loading:true
                }
            })
            .addCase(sendAdminPassword.rejected, (state, {payload,error}) => {
                return{
                    ...state,
                    passwordError: true,
                    loading:false,
                }
            })
            .addCase(adminLogout.fulfilled, (state, {payload})=>{
                localStorage.removeItem('parkId')
                return {
                    ...state,
                    data: null,
                    isValid: false
                }
            })
            .addCase(checkIsLoggedIn.fulfilled,(state, {payload})=>{
                return {
                    ...state,
                    data: {
                        ...payload
                    }
                }
            })
            .addCase(checkIsLoggedIn.pending,(state, {payload})=>{
                return {
                    ...state,
                    data: null
                }
            })
            
            .addCase(checkIsLoggedIn.rejected, (state, {payload})=>{
                return {
                    ...state,
                }
            })
            .addCase(register.fulfilled, (state, {payload})=>{
            })
            .addCase(changePassword.rejected, (state, {payload, error},)=>{
                toast.error('Մուտքագրված գաղտնաբառը սխալ է, փորձեք կրկին:')
                return {
                    ...state,
                    passwordError: true
                }
            })
            .addCase(changePassword.fulfilled, (state, {payload},)=>{
                toast.success('Գաղտնաբառը փոփոխված է։')
            })
            .addCase(checkSession.rejected,(state, {payload,error})=>{
                return{
                    ...state,
                    loading: false,
                    isValid: null
                }
            })
            .addCase(checkSession.pending,(state, {payload})=>{
                return{
                    ...state,
                    loading: true,
                }
            })
            .addCase(checkSession.fulfilled,(state, {payload})=>{
                return{
                    ...state,
                    loading: false,
                    isValid: true
                }
            })
            .addCase(fetchParkDetails.fulfilled, (state,{payload}) =>{
                return{
                    ...state,
                    date: payload[0]
                }
            })
    },
});
export const selectAdminLogin = (state) => state.adminLogin.data
export const selectAdminLoading = (state) => state.adminLogin.loading
export const selectPasswordError = (state) => state.adminLogin.passwordError
export const selectIsValid = (state) => state.adminLogin.isValid
export const {handleIsValid} = adminLoginSlice.actions
export const adminLoginReducer = adminLoginSlice.reducer;
