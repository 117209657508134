// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.homewrap {
  display: grid;
  grid-template-columns: 210px 1fr; /* Sidebar takes up 210px, content fills remaining space */
  grid-template-rows: auto 1fr;
  height: 100vh;
  width: 100vw;
}

.main-layout {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  grid-column: 2;
  grid-row: 1 / -1;
}

.header {
  grid-column: 1 / -1;
}

.outlet-content {
  grid-column: 1 / -1;
  padding: 20px;
  overflow: auto;
}

/* Hide Sidebar and adjust layout for smaller screens */
@media (max-width: 900px) {
  .homewrap {
    grid-template-columns: 1fr; /* Only the content takes up the full width */
  }
  
  .Sidebar {
    display: none; /* Hide Sidebar */
  }

  .main-layout {
    grid-column: 1; /* Make main layout span across the entire width */
  }
}
`, "",{"version":3,"sources":["webpack://./src/admin/pages/Homewrap/Homewrap.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,gCAAgC,EAAE,0DAA0D;EAC5F,4BAA4B;EAC5B,aAAa;EACb,YAAY;AACd;;AAEA;EACE,aAAa;EACb,0BAA0B;EAC1B,4BAA4B;EAC5B,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,aAAa;EACb,cAAc;AAChB;;AAEA,uDAAuD;AACvD;EACE;IACE,0BAA0B,EAAE,6CAA6C;EAC3E;;EAEA;IACE,aAAa,EAAE,iBAAiB;EAClC;;EAEA;IACE,cAAc,EAAE,kDAAkD;EACpE;AACF","sourcesContent":[".homewrap {\n  display: grid;\n  grid-template-columns: 210px 1fr; /* Sidebar takes up 210px, content fills remaining space */\n  grid-template-rows: auto 1fr;\n  height: 100vh;\n  width: 100vw;\n}\n\n.main-layout {\n  display: grid;\n  grid-template-columns: 1fr;\n  grid-template-rows: auto 1fr;\n  grid-column: 2;\n  grid-row: 1 / -1;\n}\n\n.header {\n  grid-column: 1 / -1;\n}\n\n.outlet-content {\n  grid-column: 1 / -1;\n  padding: 20px;\n  overflow: auto;\n}\n\n/* Hide Sidebar and adjust layout for smaller screens */\n@media (max-width: 900px) {\n  .homewrap {\n    grid-template-columns: 1fr; /* Only the content takes up the full width */\n  }\n  \n  .Sidebar {\n    display: none; /* Hide Sidebar */\n  }\n\n  .main-layout {\n    grid-column: 1; /* Make main layout span across the entire width */\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
