import { createSlice } from "@reduxjs/toolkit";
import {driverParks, fetchActivePark, fetchDriver, sendDriverSettings, updateDriver} from "./deiverAPI";

const driverSlice = createSlice({
    name: 'driver',
    initialState: {
        loading: false,
        islogin: false,
        settings: {
            park: '',
            percentage: 0,
            mode: 'hand'
        },
        data: null,
        parks: null,
        activePark: null
    },
    reducers:{
        startStopLoading: (state, {payload}) =>{
            return {
                ...state,
                loading: true
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchDriver.fulfilled, (state, { payload }) => {
               return{
                   ...state,
                    data: payload[0]
               }
            })
            .addCase(sendDriverSettings.fulfilled, (state, {payload}) =>{
                state.settings = payload
            })
            .addCase(driverParks.fulfilled, (state, {payload}) =>{
                if (!localStorage.getItem('activePark')){
                    localStorage.setItem('activePark', payload?.parks[0]?.id)
                }
                return{
                    ...state,
                    parks: payload?.parks
                }

            })
            .addCase(updateDriver.fulfilled, (state, {payload})=> {
                return{
                    ...state,
                    data: payload.results[0].fields
                }
            })
            .addCase(fetchActivePark.fulfilled, (state, {payload})=>{
                return {
                    ...state,
                    activePark: payload[0]
                }
            })
    },
});

export const selectDriver = (state) => state.driver;
export const selectDriverData = (state) => state.driver.data;
export const selectActivePark = (state) => state.driver.activePark
export const {startStopLoading} = driverSlice.actions
export const driverReducer = driverSlice.reducer;
