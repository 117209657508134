import { createSlice } from "@reduxjs/toolkit";
import {fetchDriverList, searchDriver, searchDrivers_page, updateDriver} from "./adminDriverListApi";

const adminDriverList = createSlice({
    name: 'driverList',
    initialState: {
        data: null,
        queryData: null
    },
    reducers:{
        clearQueryData: (state, {payload}) => {
            return{
                ...state,
                queryData: null
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchDriverList.fulfilled, (state, {payload}) => {
                console.log('what is this',{...payload})
                state.data = { ...payload };
            })
            .addCase(fetchDriverList.rejected, (state, {payload}) => {

            })
            .addCase(searchDriver.fulfilled, (state, {payload}) => {
                return{
                    ...state,
                    queryData: payload
                }
            })
            .addCase(searchDrivers_page.fulfilled, (state, {payload}) => {
                console.log('this is payload',payload)
                console.log('this is payload fields', )
                return{
                    ...state,
                    data: {
                        count: payload.count,
                        results: payload.results.map(el => el.fields),
                        total_pages: Math.ceil(payload.count / payload.limit)
                    }
                }
            })
    },
});

export const selectDriverList = (state) => state.driverList.data;
export const selectDriverQueryData = (state) => state.driverList.queryData
export const {clearQueryData} = adminDriverList.actions
export const driverListReducer = adminDriverList.reducer;
