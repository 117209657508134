// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Loading {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100vw;
    height: 100vh;
    background-color: rgb(255, 255, 255);
    display: flex;
    justify-content: center;
    align-items: center;
}

.animated-svg {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.animated-svg svg {
    fill: var(--primary);
    animation: scaleRotate 0.9s infinite ease-in-out;
}

@keyframes scaleRotate {
    0% {
        transform: scale(1) rotate(0deg);
    }
    50% {
        transform: scale(1.5) rotate(180deg);
    }
    80% {
        transform: scale(1) rotate(360deg);
    }
    100% {
        transform: scale(1) rotate(360deg);
        animation-timing-function: step-end;
    }
}

.text-below {
    margin-top: 10px;
    font-size: 16px;
    color: white;
}
`, "",{"version":3,"sources":["webpack://./src/sharedComponents/Loading/Loading.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,aAAa;IACb,YAAY;IACZ,aAAa;IACb,oCAAoC;IACpC,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,SAAS;AACb;;AAEA;IACI,oBAAoB;IACpB,gDAAgD;AACpD;;AAEA;IACI;QACI,gCAAgC;IACpC;IACA;QACI,oCAAoC;IACxC;IACA;QACI,kCAAkC;IACtC;IACA;QACI,kCAAkC;QAClC,mCAAmC;IACvC;AACJ;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,YAAY;AAChB","sourcesContent":[".Loading {\n    position: absolute;\n    top: 0;\n    left: 0;\n    z-index: 9999;\n    width: 100vw;\n    height: 100vh;\n    background-color: rgb(255, 255, 255);\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.animated-svg {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    gap: 20px;\n}\n\n.animated-svg svg {\n    fill: var(--primary);\n    animation: scaleRotate 0.9s infinite ease-in-out;\n}\n\n@keyframes scaleRotate {\n    0% {\n        transform: scale(1) rotate(0deg);\n    }\n    50% {\n        transform: scale(1.5) rotate(180deg);\n    }\n    80% {\n        transform: scale(1) rotate(360deg);\n    }\n    100% {\n        transform: scale(1) rotate(360deg);\n        animation-timing-function: step-end;\n    }\n}\n\n.text-below {\n    margin-top: 10px;\n    font-size: 16px;\n    color: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
