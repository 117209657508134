// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.flag{
    width: 25px;
    aspect-ratio: 1;
    object-fit: cover;
    border-radius: 50%;
    cursor: pointer;
    border: 2px solid var(--primary);
}
.activeFlag{
    box-shadow: 0px 0px 20px 4px var(--primary );
}
.flagContainer{
    display: flex;
    flex-direction: column;
}
.LanguageChange{
    display: flex;
    gap: 15px;
    width: 100%;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 100%;
    transform: translate(0, -100%);
    padding-bottom: 20px;
}


@media only screen and (max-height:350px) {
   .LanguageChangeLabels{
    display: none;
   }
}`, "",{"version":3,"sources":["webpack://./src/sharedComponents/LanguageChange/LanguageChange.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,eAAe;IACf,iBAAiB;IACjB,kBAAkB;IAClB,eAAe;IACf,gCAAgC;AACpC;AACA;IACI,4CAA4C;AAChD;AACA;IACI,aAAa;IACb,sBAAsB;AAC1B;AACA;IACI,aAAa;IACb,SAAS;IACT,WAAW;IACX,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;IAClB,SAAS;IACT,8BAA8B;IAC9B,oBAAoB;AACxB;;;AAGA;GACG;IACC,aAAa;GACd;AACH","sourcesContent":[".flag{\n    width: 25px;\n    aspect-ratio: 1;\n    object-fit: cover;\n    border-radius: 50%;\n    cursor: pointer;\n    border: 2px solid var(--primary);\n}\n.activeFlag{\n    box-shadow: 0px 0px 20px 4px var(--primary );\n}\n.flagContainer{\n    display: flex;\n    flex-direction: column;\n}\n.LanguageChange{\n    display: flex;\n    gap: 15px;\n    width: 100%;\n    align-items: center;\n    justify-content: center;\n    position: absolute;\n    top: 100%;\n    transform: translate(0, -100%);\n    padding-bottom: 20px;\n}\n\n\n@media only screen and (max-height:350px) {\n   .LanguageChangeLabels{\n    display: none;\n   }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
