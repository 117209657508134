import {createAsyncThunk} from "@reduxjs/toolkit";
import axios from '../../../axiosInstance';
import API_BASE_URL from "../../../../apiRoute";
function generateCSRFToken() {
    const array = new Uint8Array(32); // 32 bytes = 256 bits
    window.crypto.getRandomValues(array);
    return Array.from(array, byte => byte.toString(16).padStart(2, '0')).join('');
}
// export const sendAdminPassword = createAsyncThunk(
//     'adminLogin/sendAdminPassword',
//     async function ({ username, password }) {
//
//         const response = await axios.post(`https://api.diamondpay.am/api/login`, {
//             username,
//             password
//         },
//             {
//                 headers: {
//                     'Content-Type': 'application/json',
//                 }
//             });
//
//         const data = response.data;
//         return data;
//     }
// );

export const sendAdminPassword = createAsyncThunk(
    'adminLogin/sendAdminPassword',
    async function ({id, password}){
        const response = await axios.post(`${API_BASE_URL}/api/token/`,{
            id,
            password,
            role: 'admin'
        },{
            headers: {
                'Content-Type': 'application/json'
            }
        })
        const data = response.data
        return data
    }
)
export const adminList = createAsyncThunk(
    'adminLogin/adminList',
    async function (phone_number) {
        const response = await axios.get(`${API_BASE_URL}/users/admin/list/`, {
            params: {
                phone_number
            },
            headers: {
                'Content-Type': 'application/json'
            },
        });
        const data = response.data;
        return data;
    }
);


const getCsrfToken = () => {
    const match = document.cookie.match(/(^|;)\\s*csrftoken=([^;]+)/);
    return match ? match[2] : null;
};

export const checkSession = createAsyncThunk(
    'adminLogin/checkSession',
    async function () {
        const accessToken = localStorage.getItem('accessToken');
        const csrfToken = getCsrfToken(); // Retrieve CSRF token from cookies
        const response = await axios.get(`${API_BASE_URL}/api/check-auth/`, {
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrfToken, // ✅ Include CSRF token in headers
                'Authorization': `Bearer ${accessToken}`
            },
        });

        const data = response.data;
        return data;
    }
);

export const adminLogout = createAsyncThunk(
    'adminLogin/adminLogout',
    async function () {
        const response = await axios.post(`${API_BASE_URL}/users/logout/`,{
            headers: {
                'Content-Type': 'application/json'
            }
        })
        const data = response.data
        return data
    }
)
//users/check-session/
// export const adminRegister = createAsyncThunk(
//     'adminLogin/adminRegister',
//     async function ({username, password}) {
//         const response = await axios.post(`${API_BASE_URL}users/generate_password/`,{
//             username,
//             password
//         },{
//             headers: {
//                 'Content-Type': 'application/json',
//             }
//         })
//         const data = response.data
//         return data
//     }
// )
// export const adminLogout = createAsyncThunk(
//     'adminLogin/adminLogout',
//     async function () {
//         await axios.get(`https://api.diamondpay.am/api/logout`)
//     }
// );

export const checkIsLoggedIn = createAsyncThunk(
    'adminLogin/checkIsLoggedIn',
    async function () {
        const response = await axios.get(`https://api.diamondpay.am/api/park`);
        const data = response.data;
        return data;
    }
);
export const register = createAsyncThunk(
    'adminLogin/register',
    async function ({ username, password, park_id, client_id, yandex_key, idram, idram_key }) {

        const response = await axios.post(`https://api.diamondpay.am/api/park/register`, {
            username,
            password,
            park_id,
            client_id,
            yandex_key,
            idram,
            idram_key
        },
            {
                headers: {
                    'Content-Type': 'application/json',
                }
            });

        const data = response.data;
        return data;
    }
);

export const changePassword = createAsyncThunk(
    'adminLogin/changePassword',
    async function ({ username, old_password, password }) {
        const accessToken = localStorage.getItem('accessToken');
        const response = await axios.post(`${API_BASE_URL}/users/reset_password/`, {
                username,
                old_password,
                password
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                }
            });

        return response.data;
    }
);
export const applySettings = createAsyncThunk(
    'adminLogin/applySettings',
    async function (settings) {
        const park_id = localStorage.getItem('park_id');
        const accessToken = localStorage.getItem("accessToken")
        const response = await axios.patch(
            `${API_BASE_URL}/park/park/update/${park_id}/`,
            {
                ...settings
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                }
            }
        );

        return response.data;
    }
);
export const changeParkAccessBonus = createAsyncThunk(
    'adminLogin/changeParkAccessBonus',
    async function (access_bonus) {
        const park_id = localStorage.getItem('park_id');
        const response = await axios.patch(
            `${API_BASE_URL}/park/park/update/${park_id}/`,
            {
                access_bonus
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                }
            }
        );

        return response.data;
    }
);
export const changeAllDriversAccessBonus = createAsyncThunk(
    'adminLogin/changeAllDriversAccessBonus',
    async function (access_bonus) {
        const park_id = localStorage.getItem('park_id');
        const accessToken = localStorage.getItem('accessToken')
        const response = await axios.post(
            `${API_BASE_URL}/park/permissions/`,
            {
                park_id,
                all_drivers: access_bonus
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                }
            }
        );

        return response.data;
    }
);
export const fetchParkDetails = createAsyncThunk(
    'adminLogin/fetchParkDetails',
    async function () {
        const park_id = localStorage.getItem('park_id');
        const accessToken = localStorage.getItem('accessToken')
        const response = await axios.get(`${API_BASE_URL}/park/park/list/?park_id=${park_id}`,{
            headers:{
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            }
        });
        const data = response.data;
        console.log('park', data[0])
        return data;
    }
);