import './AdminOrderPage.css';
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchSingleOrder } from "../../../store/slices/adminSlices/adminOrderList/adminOrderAPI";
import { selectSingleOrderList } from "../../../store/slices/adminSlices/adminOrderList/adminOrderList";

const AdminOrderPage = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const order = useSelector(selectSingleOrderList);

    useEffect(() => {
        dispatch(fetchSingleOrder(id));
    }, [dispatch, id]);
    function calculateTimeDifference(date1, date2) {
        const dateObj1 = new Date(date1);
        const dateObj2 = new Date(date2);
        if (isNaN(dateObj1) || isNaN(dateObj2)) {
            return "Անվավեր ամսաթիվ";
        }
        const diffInMilliseconds = Math.abs(dateObj2 - dateObj1);
        const hours = Math.floor(diffInMilliseconds / (1000 * 60 * 60));
        const minutes = Math.floor((diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
        if (hours === 0) {
            return `${minutes} րոպե`;
        }
        return `${hours} ժամ ${minutes} րոպե`;
    }

    if (!order) {
        return <div className="notFound">Պատվերը չի գտնվել</div>;
    }

    return (
        <div className="AdminOrderPage">
            <div className="orderHeader">
                <h1>Պատվերի Մանրամասներ</h1>
            </div>

            <div className="orderDetails">
                <div className="order_info">
                    <div className="driverInfo">
                        <h2 className='driverInfo_title'>Վարորդի Տվյալներ</h2>
                        <div className="infoCard">
                            <p><strong>Անուն:</strong> {order.driver_name}</p>
                            <p><strong>Հեռախոս:</strong> {order.phone_number}</p>

                        </div>
                    </div>
                    <div className="driverInfo">
                        <h2>Պատվերի Տվյալներ</h2>
                        <div className="infoCard">
                            <p><strong>Վճարման Մեթոդ:</strong> {
                                order.payment_method === 'cashless' ? 'Անկանխիկ' :
                                    order.payment_method === 'corp' ? 'Կորպորատիվ' :
                                        order.payment_method === 'cash' ? 'Կանխիկ' :
                                            "--"
                            }</p>
                            <p><strong>Գումար:</strong> {order.price} ֏</p>
                            <p><strong>Հեռավորություն:</strong> {(order.mileage / 1000).toFixed(1)} կմ</p>
                            <p>
                                <strong>Տեվողություն:</strong> {calculateTimeDifference(order.order_time_interval_start, order.order_time_interval_end)}
                            </p>
                        </div>
                    </div>
                    <div className="routeInfo">
                        <h2>Ուղղություն</h2>
                        <div className="infoCard">
                            <div className="address">
                                <h3>Վերցնելու Հասցե</h3>
                                <p>{order.address_from?.address || '--'}</p>
                            </div>
                            <div className="address">
                                <h3>Իջնելու Հասցե</h3>
                                <p>{order.route_points[0]?.address || '--'}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="orderTimeline">
                    <h2 className='order_history_title'>Պատվերի Պատմություն</h2>
                    <div className="timeline">
                        {order.events?.map((event, index) => (
                            <div key={index} className="timelineEvent">
                                <div className="timelineIcon">
                                    {event.order_status === 'driving' && <i className="fa fa-road"></i>}
                                    {event.order_status === 'waiting' && <i className="fa fa-clock"></i>}
                                    {event.order_status === 'transporting' && <i className="fa fa-taxi"></i>}
                                    {event.order_status === 'complete' && <i className="fa fa-check"></i>}
                                    {event.order_status === 'cancelled' && <i className="fa fa-times"></i>}
                                </div>
                                <div className="timelineContent">
                                    <p><strong>Կարգավիճակ:</strong> {
                                        event.order_status === 'driving' ? 'Սկսել է' :
                                            event.order_status === 'waiting' ? 'Ժամանել է' :
                                                event.order_status === 'transporting' ? 'Շարժվել է' :
                                                    event.order_status === 'complete' ? 'Ավարտել է' :
                                                        event.order_status
                                    }</p>
                                    <p><strong>Ժամանակ:</strong> {new Date(event.event_at).toLocaleString()}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

            </div>
        </div>
    );
};

export default AdminOrderPage;