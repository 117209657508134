// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CircularProgress{
    width: 100%;
    height: fit-content;
    padding: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}


@media only screen and (max-height:756px) {
  .CircularProgress{
    padding: 20px;
  }
}

@media only screen and (max-height:706px) {
    .CircularProgress{
      padding: 0px;
    }
  }`, "",{"version":3,"sources":["webpack://./src/sharedComponents/CircularProgres/CircularProgres.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,mBAAmB;IACnB,aAAa;IACb,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;;AAGA;EACE;IACE,aAAa;EACf;AACF;;AAEA;IACI;MACE,YAAY;IACd;EACF","sourcesContent":[".CircularProgress{\n    width: 100%;\n    height: fit-content;\n    padding: 50px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n\n@media only screen and (max-height:756px) {\n  .CircularProgress{\n    padding: 20px;\n  }\n}\n\n@media only screen and (max-height:706px) {\n    .CircularProgress{\n      padding: 0px;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
