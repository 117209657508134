import Transactions from "./components/Transactions";
import Transfers from "./components/Transfers";
import Dashboard from "./components/Dashboard";
import Homewrap from './pages/Homewrap/';
import { Routes, Route } from 'react-router-dom'; 
import './adminApp.css';
import DriversPage from "./components/DriversPage";
import AdminLogin from "./components/AdminLogin";
import AdminSettings from "./components/AdminSettings/AdminSettings";
import AdminBonus from "./components/AdminBonus/AdminBonus";
import AdminMessages from "./components/AdminMessages/AdminMessages";
import AdminContactUs from "./components/AdminConactUs/AdminContactUs";
import AdminOrderPage from "./components/AdminOrderPage/AdminOrderPage";
import AdminOrders from "./components/AdminOrders/AdminOrders";
import ProtectedRoute from "../sharedComponents/ProtectedRoute";
import Loading from "../sharedComponents/Loading/Loading";
import {selectAdminLoading} from "../store/slices/adminSlices/adminLoginSlice/adminLoginSlice";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import AntiFraud from "./components/AntiFraud/AntiFraud";
import DriverDetails from "./components/DriverDetails/DriverDetails";

function AdminApp() {
    const isLoading = useSelector(selectAdminLoading)
    const dispatch = useDispatch();
    useEffect(() => {
        if (localStorage.getItem('park_id')) {

        }
    },[])
  return (
    <div className="AdminApp">
      <Routes>
        <Route path="login" element={<AdminLogin/>}/>
        <Route path="/" element={<Homewrap />}>
            <Route element={<ProtectedRoute/>}>
                <Route index element={<Dashboard />} />
            </Route>
            <Route element={<ProtectedRoute/>}>
                <Route path="dashboard" element={<Dashboard />} />
            </Route>
            <Route element={<ProtectedRoute/>}>
                <Route path="orders" element={<Transactions />} />
            </Route>
            <Route element={<ProtectedRoute/>}>
                <Route path="transactions" element={<AdminOrders />} />
            </Route>
            <Route element={<ProtectedRoute/>}>
                <Route path="orders/order/:id" element={<AdminOrderPage />} />
            </Route>
            <Route element={<ProtectedRoute/>}>
                <Route path="transfers" element={<Transfers />} />
            </Route>
            <Route element={<ProtectedRoute/>}>
                <Route path="drivers" element={<DriversPage />} />
            </Route>
            <Route element={<ProtectedRoute/>}>
                <Route path="drivers/:id" element={<DriverDetails />} />
            </Route>
            <Route element={<ProtectedRoute/>}>
                <Route path="settings" element={<AdminSettings />} />
            </Route>
            <Route element={<ProtectedRoute/>}>
                <Route path="bonus-system" element={<AdminBonus />} />
            </Route>
            <Route element={<ProtectedRoute/>}>
                <Route path="anti-fraud" element={<AntiFraud />} />
            </Route>
            <Route element={<ProtectedRoute/>}>
                <Route path="contact-us" element={<AdminContactUs />} />
            </Route>
        </Route>
      </Routes>
    </div>
  );
}

export default AdminApp;
