import { useDispatch, useSelector } from "react-redux";
import "./Transfers.css";
import React, { useEffect, useState, useRef } from "react";
import {
    clearQueryData,
    selectDriverQueryData
} from "../../../store/slices/adminSlices/adminDriverList/adminDriverList";
import { toast } from "react-toastify";
import { searchDriver } from "../../../store/slices/adminSlices/adminDriverList/adminDriverListApi";
import { sendAdminTransfer } from "../../../store/slices/adminSlices/adminTransferSlice/adminTransferAPI";

const Transfers = () => {
    const dispatch = useDispatch();
    const queryData = useSelector(selectDriverQueryData);
    const [inputs, setInputs] = useState({ driver: '', amount: '' });
    const [driverId, setDriverId] = useState('');
    const dropdownRef = useRef(null); // Reference for dropdown
    const [transferPending, setTransferPending] = useState(false)
    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'driver' && value.length > 2) {
            let newVal = value.replace(/\+/g, '%2B')
            dispatch(searchDriver({ query: newVal }));
        }
        if (name === 'driver' && value.length === 0) {
            dispatch(clearQueryData());
        }
        if (name === 'driver') setDriverId('');
        setInputs({ ...inputs, [name]: value });
    };

    const handleDriverName = (driver) => {
        setDriverId(driver.profile_id);
        setInputs({ ...inputs, driver: driver.driver_name });
        dispatch(clearQueryData()); // Clear dropdown after selection
    };

    const handleSendTransfer =async (e) => {
        e.preventDefault();
        if (!driverId) {
            toast.error('Վարորդը ընտրված չէ');
            return;
        }
        if (inputs.amount > 0) {
            setTransferPending(true)
            await dispatch(sendAdminTransfer({ price: inputs.amount, driver_id: driverId }));
            setTransferPending(false)
            setDriverId('')
            setInputs({  driver: '', amount: ''});
        }
    };

    // Click outside handler
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                dispatch(clearQueryData());
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dispatch]);

    return (
        <div className='Transfers'>
            <div className="form">
                {transferPending? (
                    <div className={'modalSpinnerContainer'}>
                        <div className="modalSpinner"></div>
                    </div>
                ) : (
                    <>
                        <span className='TransfersHeading'>Փոխանցել Իդրամին</span>
                        <form>
                            <div className="inputContainer" ref={dropdownRef}>
                                {driverId ? (
                                    <div className={'transferDriverName'}>
                                        {inputs.driver}
                                        <i onClick={() => setDriverId('')} className="fa-solid fa-xmark"></i>
                                    </div>
                                ) : (
                                    <input
                                        type="text"
                                        onChange={handleChange}
                                        value={inputs.driver}
                                        name="driver"
                                        autoComplete={'off'}
                                    />
                                )}

                                {/* Dropdown */}
                                {!driverId && queryData?.results?.length > 0 && (
                                    <div className="queryData">
                                        {queryData.results.map((driver, index) => (
                                            <span
                                                onClick={() => handleDriverName(driver.fields)}
                                                className={'queryDataDriverName'}
                                                key={index}
                                            >
                                        {driver.fields.driver_name}
                                    </span>
                                        ))}
                                    </div>
                                )}
                                {!driverId && (
                                    <span
                                        className={inputs.driver ? 'filled floatingPlaceholder' : 'floatingPlaceholder'}>
                                Վարորդ, հեռ.համար, IDram
                            </span>
                                )}
                            </div>
                            <div className="inputContainer">
                                <input
                                    type="text"
                                    onChange={handleChange}
                                    name="amount"
                                    value={inputs.amount}
                                />
                                <span className={inputs.amount ? 'filled floatingPlaceholder' : 'floatingPlaceholder'}>
                            Գումար
                        </span>
                            </div>
                            <button className={'AdminTransferBtn'} onClick={handleSendTransfer}>Ուղարկել</button>
                        </form>
                    </>
                )}

            </div>
        </div>
    );
};

export default Transfers;
