import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from '../../../axiosInstance';
import API_BASE_URL from "../../../../apiRoute";

export const fetchAdminOrderList = createAsyncThunk(
    'adminOrderList/fetchAdminOrderList',
    async function ({ limit = 20, offset = 0 }) {
        const accessToken = localStorage.getItem('accessToken'); // Retrieve access token
        const park_id = localStorage.getItem('park_id');
        const response = await axios.get(
            `${API_BASE_URL}/utils/orders/list/?limit=${limit}&offset=${offset}&status=complete&park_id=${park_id}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`, // Add access token
                },
            }
        );
        console.log(response.data);
        return response.data;
    }
);

export const sendOrderTransfer = createAsyncThunk(
    'adminOrderList/sendOrderTransfer',
    async function ({order_id, park_id}) {
        
        const response = await axios.post(`https://api.diamondpay.am/api/park/orders/send`, {
                order_id,
                park_id
        }, 
           { headers: {
                'Content-Type': 'application/json',
            }
        });
        const data = response.data;
        return data;
    }
);

export const fetchSingleOrder = createAsyncThunk(
    'adminOrderList/fetchSingleOrder',
    async function (order_id) {
        const accessToken = localStorage.getItem('accessToken'); // Retrieve access token
        const response = await axios.get(`${API_BASE_URL}/utils/orders/list/?order_id=${order_id}`,
            { headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                }
            });
        console.log(response.data)
        const data = response.data[0];
        return data;
    }
);