import React, { useEffect, useState } from 'react';
import './Transactions.css'; // Import the CSS file
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Box, Select, MenuItem, FormControl, Typography, useMediaQuery } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAdminOrderList, sendOrderTransfer } from '../../../store/slices/adminSlices/adminOrderList/adminOrderAPI';
import { selectAdminOrderList, selectAdminOrderListSendData } from '../../../store/slices/adminSlices/adminOrderList/adminOrderList';
import PageHeader from '../PageHeader';
import { checkIsLoggedIn } from '../../../store/slices/adminSlices/adminLoginSlice/adminLoginAPI';
import { useNavigate } from 'react-router-dom';
import { selectAdminLoading, selectAdminLogin } from '../../../store/slices/adminSlices/adminLoginSlice/adminLoginSlice';
import { sendAdminTransfer } from '../../../store/slices/adminSlices/adminTransferSlice/adminTransferAPI';
import { toast } from 'react-toastify';

const Transactions = () => {
    const dispatch = useDispatch();
    const orderList = useSelector(selectAdminOrderList);
    const data = orderList?.results || [];
    const navigate = useNavigate()
    const adminLogin = useSelector(selectAdminLogin)
    const loading = useSelector(selectAdminLoading)
    const sendData = useSelector(selectAdminOrderListSendData)
    const isSmallScreen = useMediaQuery('(max-width:600px)');
    const [filterValues, setFilterValues] = useState({
        dateRange: [],
        dateRangeDates: [],
        status: [],
        inputVal: '',
        driver_id: ''
    });
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);

    function formatTimestamp(timestamp) {
        const date = new Date(timestamp);
        const day = String(date.getUTCDate()).padStart(2, '0');
        const month = String(date.getUTCMonth() + 1).padStart(2, '0');
        const year = date.getUTCFullYear();
        const hours = String(date.getUTCHours()).padStart(2, '0');
        const minutes = String(date.getUTCMinutes()).padStart(2, '0');

        return `${day}-${month}-${year}/${hours}:${minutes}`;
    }

    const handleStatus = (status) => {
        switch (status) {
            case 'completed':
                return 'statusCompleted'
            case 'hand':
                return 'statusHand'
            case 'yandex_balance':
                return 'statusYB'
            case 'balance':
                return 'statusBalance'
            case 'idk':
                return 'statusIdk'
            case 'yandex_error':
                return 'statusYE'
            default:
                break;
        }
    }
    const statusName = (status) => {
        switch (status) {
            case 'complete':
                return 'Կատարված'
            case 'hand':
                return 'Ձեռքով ռեժիմ'
            case 'yandex_balance':
                return 'Անբավարար միջոցներ'
            case 'balance':
                return 'Ընթացքում'
            case 'idk':
                return 'Ընթացքում'
            case 'yandex_error':
                return 'Yandex խնդիր'
            default:
                break;
        }
    }
    const handleChangePage = (newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const paginatedData = data;
    const numberOfPages = orderList?.total_pages;
    const getFirstDayOfCurrentMonth = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
        const day = '01'; // First day of the month
        return `${year}-${month}-${day}`;
    };

    const handleSend = (park_id, order_id) =>{
        dispatch(sendOrderTransfer({park_id, order_id}))
    }

    // useEffect(() => {
    //     if (adminLogin?.park_id) {
    //         if (filterValues.dateRange[0]) {
    //             dispatch(fetchAdminOrderList({
    //                 park_id: adminLogin?.park_id,
    //                 limit: rowsPerPage,
    //                 offset: page * rowsPerPage,
    //                 start: filterValues.dateRange[0],
    //                 end: filterValues[1],
    //                 driver_id: filterValues.driver_id,
    //                 status: filterValues.status
    //             }));
    //         } else {
    //             dispatch(fetchAdminOrderList({
    //                 park_id: adminLogin?.park_id,
    //                 limit: rowsPerPage,
    //                 offset: page * rowsPerPage,
    //                 start: getFirstDayOfCurrentMonth(),
    //                 driver_id: filterValues.driver_id,
    //                 status: filterValues.status
    //             }));
    //         }
    //     }
    //
    // }, [page, adminLogin]);

    useEffect(() => {
        dispatch(fetchAdminOrderList({limit: rowsPerPage, offset: page * rowsPerPage}))
    }, [page, rowsPerPage]);

    const renderPagination = () => {
        const pagesToShow = isSmallScreen ? 1 : 4; // Show fewer pages on small screens
        const startPage = Math.max(0, page - Math.floor(pagesToShow / 2));
        const endPage = Math.min(startPage + pagesToShow - 1, numberOfPages - 1);

        const pages = [];

        if (startPage > 0) {
            pages.push(
                <Button key={0} onClick={() => handleChangePage(0)} variant="outlined">
                    1
                </Button>,
                startPage > 1 && <Typography key="start-ellipsis" variant="body2">...</Typography>
            );
        }

        for (let i = startPage; i <= endPage; i++) {
            pages.push(
                <Button
                    key={i}
                    onClick={() => handleChangePage(i)}
                    variant="outlined"
                    sx={{
                        mx: 0.5,
                        fontSize: isSmallScreen ? '12px' : 'inherit', // Smaller font size on small screens
                        color: i === page ? 'white' : 'inherit',
                        borderColor: i === page ? '#FF5C00' : 'black',
                        backgroundColor: i === page ? '#FF5C00' : 'transparent',
                    }}
                >
                    {i + 1}
                </Button>
            );
        }

        if (endPage < numberOfPages - 1) {
            pages.push(
                endPage < numberOfPages - 2 && <Typography key="end-ellipsis" variant="body2">...</Typography>,
                <Button key={numberOfPages - 1} onClick={() => handleChangePage(numberOfPages - 1)} variant="outlined">
                    {numberOfPages}
                </Button>
            );
        }

        return pages;
    };

    function formatDateString(dateString) {
        const date = new Date(dateString);
        const day = String(date.getUTCDate()).padStart(2, '0');
        const month = String(date.getUTCMonth() + 1).padStart(2, '0');
        const year = date.getUTCFullYear();
        const hours = String(date.getUTCHours()).padStart(2, '0');
        const minutes = String(date.getUTCMinutes()).padStart(2, '0');
        return `${day}-${month}-${year}/${hours}:${minutes}`;
    }
    return (
        <>
            <PageHeader
                heading={'Պատվերներ'}
                page={'transactions'}
                filterValues={filterValues}
                setFilterValues={setFilterValues}
            />
            <TableContainer component={Paper}>
                <Box display="flex" alignItems="center" justifyContent="space-between" px={2} mt={2}>
                    {!isSmallScreen && ( // Conditionally render this part based on screen size
                        <Box display="flex" alignItems="center">
                            <Typography variant="body1" sx={{ marginRight: 1 }}>Ցուցադրվող քանակ:</Typography>
                            <FormControl variant="outlined" size="small">
                                <Select value={rowsPerPage} onChange={handleChangeRowsPerPage}>
                                    <MenuItem value={10}>10</MenuItem>
                                    <MenuItem value={20}>20</MenuItem>
                                    <MenuItem value={50}>50</MenuItem>
                                    <MenuItem value={100}>100</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    )}

                    <Box display="flex" alignItems="center" justifyContent="center" flexWrap="nowrap" padding='0'>
                        <Button onClick={() => handleChangePage(page - 1)} disabled={page === 0} sx={{ color: '#FF5C00' }}>
                            &lt;
                        </Button>
                        {renderPagination()}
                        <Button onClick={() => handleChangePage(page + 1)} disabled={page >= numberOfPages - 1} sx={{ color: '#FF5C00' }}>
                            &gt;
                        </Button>
                    </Box>
                </Box>
                {isSmallScreen ? <div className="TransactionsContainer">
                        {paginatedData.map((el, index) => (
                            <div key={index} className="TransactionCard">
                                <h3 className="TransactionTitle">{el.driver_name}</h3> {/* Updated to use driver_name */}
                                <div className="TransactionCardContainer">
                                    <div className="TransactionCardInfo">
                                        <span className="TransactionCardLabel">Idram:</span>
                                        <span className="TransactionCardValue">{el.idram}</span>
                                    </div>
                                    <div className="TransactionCardInfo">
                                        <span className="TransactionCardLabel">Գումար:</span>
                                        <span className="TransactionCardValue">{el.price}</span>
                                    </div>
                                    <div className="TransactionCardInfo">
                                        <span className="TransactionCardLabel">Հեռախոս:</span>
                                        <span className="TransactionCardValue">{el.phone_number}</span>
                                    </div>
                                    <div className="TransactionCardInfo">
                                        <span className="TransactionCardLabel">Վիճակ:</span>
                                        <div className={`status`}>{statusName(el.status)}</div>
                                    </div>
                                    <div className="TransactionCardInfo">
                                        <span className="TransactionCardLabel">Սկիզբ:</span>
                                        <span
                                            className="TransactionCardValue">{formatDateString(el.order_time_interval_start)}</span>
                                    </div>
                                    <div className="TransactionCardInfo">
                                        <span className="TransactionCardLabel">Ավարտ:</span>
                                        <span
                                            className="TransactionCardValue">{formatDateString(el.order_time_interval_end)}</span>
                                    </div>
                                    <div className="TransactionCardInfo">
                                        <span className="TransactionCardLabel">Բալանս:</span>
                                        <span className="TransactionCardValue">{el.balance.toFixed(0)}</span>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div> :
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Վարորդ</TableCell>
                                <TableCell>Հեռախոս</TableCell>
                                <TableCell>Իդրամ</TableCell>
                                <TableCell>Գումար</TableCell>
                                <TableCell>Բալանս</TableCell>
                                <TableCell>Սկիզբ</TableCell>
                                <TableCell>Ավարտ</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {orderList?.results?.map((el, index) => (
                                <TableRow key={index} sx={{
                                    cursor: 'pointer',
                                    backgroundColor: index % 2 === 0 ? 'grey.100' : 'white',
                                    transition: '0.3s all ease-in-out',
                                    '&:hover': {
                                        backgroundColor: 'grey.200',
                                    },
                                }}>
                                    <TableCell>{el.driver_name}</TableCell>
                                    <TableCell>{el.phone_number}</TableCell>
                                    <TableCell>{el.idram}</TableCell>
                                    <TableCell>{el.price}</TableCell>
                                    <TableCell>{el.balance.toFixed(0)}</TableCell>
                                    <TableCell>{formatTimestamp(el.order_time_interval_start)}</TableCell>
                                    <TableCell>{formatTimestamp(el.order_time_interval_end)}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>

                }

                {/* Bottom Pagination */}
                <Box display="flex" alignItems="center" justifyContent="space-between" px={2} mt={2}>
                    {!isSmallScreen && ( // Conditionally render this part based on screen size
                        <Box display="flex" alignItems="center">
                            <Typography variant="body1" sx={{ marginRight: 1 }}>Ցուցադրվող քանակ:</Typography>
                            <FormControl variant="outlined" size="small">
                                <Select value={rowsPerPage} onChange={handleChangeRowsPerPage}>
                                    <MenuItem value={10}>10</MenuItem>
                                    <MenuItem value={20}>20</MenuItem>
                                    <MenuItem value={50}>50</MenuItem>
                                    <MenuItem value={100}>100</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    )}
                    <Box display="flex" alignItems="center" justifyContent="center" flexWrap="nowrap">
                        <Button onClick={() => handleChangePage(page - 1)} disabled={page === 0} sx={{ color: '#FF5C00' }}>
                            &lt;
                        </Button>
                        {renderPagination()}
                        <Button onClick={() => handleChangePage(page + 1)} disabled={page >= numberOfPages - 1} sx={{ color: '#FF5C00' }}>
                            &gt;
                        </Button>
                    </Box>
                </Box>
            </TableContainer>
        </>
    );
};

export default Transactions;
