import { Link } from 'react-router-dom';
import './DriverHeader.css'
import { useSelector } from 'react-redux';
import {selectDriver, selectDriverData} from '../../../store/slices/deiverSlice/deiverSlice';
import {selectLoginInput} from "../../../store/slices/loginInputSlice/loginInputSlice";


const DriverHeader = () => {
    const driver = useSelector(selectDriverData);
    return (
        <div className='DriverHeader'>
            <div className="driver">
                <span>{driver?.driver_name}</span>
            </div>
            <div className="settings-icon">
                <Link to="/settings" className='SettingsLink'><i className='fa fa-cog'></i></Link>
            </div>
        </div>
    );
}

export default DriverHeader;
