export const languageProvider = ({page, lang}) => {
        switch (page) {
            case 'settings':
                if (lang === 'arm') {
                    return {
                        heading: 'Կարգավորումներ',
                        park: 'Yandex գործընկեր',
                        mode: 'Փոխանցման ռեժիմ',
                        modeAuto: 'Ավտոմատ',
                        modeHand: 'Ձեռքով',
                        percentage: 'Փոխանցվող տոկոս',
                        apply: 'Հաստատել',
                        logout: 'Ելք',
                    }
                }else if(lang === 'rus'){
                    return {
                        heading: 'Настройки',
                        park: 'Yandex партнёр',
                        mode: 'Режим первода',
                        modeAuto: 'Автоматический',
                        modeHand: 'Ручной',
                        percentage: 'Процент перевода',
                        apply: 'Применить',
                        logout: 'Выйти',
                    }
                }
                else if(lang === 'eng'){
                    return {
                        heading: 'Settings',
                        park: 'Yandex partner',
                        mode: 'Transfer mode',
                        modeAuto: 'Auto',
                        modeHand: 'Manual',
                        percentage: 'Transfer percentage',
                        apply: 'Apply',
                        logout: 'Logout',
                    }
                }
                break;
            case 'main': {
                if (lang === 'arm') {
                    return {
                        balnace: 'Բալանս',
                        sent: 'Փոխանցված',
                        seeOrders: 'Տեսնել բոլոր գործարքները',
                        canceled: 'Չեղարկված',
                        sendIdram: 'Փոխանցել IDram-ին',
                        send: 'Փոխանցել'
                    }
                }else if(lang === 'rus'){
                    return {
                        balnace: 'Баланс',
                        sent: 'Переведено',
                        seeOrders: 'Посмотреть все переводы',
                        canceled: 'Отменено',
                        sendIdram: 'Перевести на IDram',
                        send: 'Перевести'
                    }
                }else if(lang === 'eng'){
                    return {
                        balnace: 'Balance',
                        sent: 'Transfered',
                        seeOrders: 'See all transfers',
                        canceled: 'Canceled',
                        sendIdram: 'Transfer to IDram',
                        send: 'Transfer'
                    }
                }
                break;
            }
            case 'login': {
                if (lang === 'arm') {
                    return {
                        heading: 'Բարի Գալուստ',
                        hint: 'Մուտքագրեք ձեր հեռախոսահամարը',
                        hintPassword: 'Մուտքագրեք ձեր գաղտնաբառը',
                        hintReg: 'Ստեղծեք ձեր գաղտնաբառը',
                        next: 'Առաջ',
                        login: 'Մուտք',
                        reg: 'Գրանցվել'
                    }
                }else if(lang === 'rus'){
                    return {
                        heading: 'Добро пожаловать',
                        hint: 'Введите номер телефона',
                        hintPassword: 'Введите ваш пароль',
                        hintReg: 'Создайте ваш пароль',
                        next: 'Дальше',
                        login: 'Войти',
                        reg: 'Зарегистрироваться'
                    }
                }else if(lang === 'eng'){
                     return {
                        heading: 'Welcome',
                        hint: 'Enter phone number',
                        hintPassword: 'Enter your password',
                        hintReg: 'Create your password',
                        next: 'Next',
                        login: 'Log in',
                        reg: 'Register'
                     }   
                }
            }
            default:
                break;
        }
}