import DriverHeader from "../DriverHeader";
import MainInfo from "../MainInfo";
import Transfer from "../Transfer";
import {useEffect} from "react";
import {useDispatch} from "react-redux";
import {fetchActivePark, fetchDriver, updateDriver} from "../../../store/slices/deiverSlice/deiverAPI";

const DriverHome = () => {
    const dispatch = useDispatch();
    const driver_id = localStorage.getItem("driverInfo");
    const active_park = localStorage.getItem("activePark");
    useEffect(() => {
        if (driver_id) {
            dispatch(fetchDriver(driver_id))
        }
        if (active_park){
            dispatch(fetchActivePark(active_park))
        }
    }, []);
    return(
        <div className='DriverHome'>
            <DriverHeader />
            <MainInfo />
            <Transfer />
        </div>
    )
}

export default DriverHome