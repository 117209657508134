import {createAsyncThunk} from "@reduxjs/toolkit";
import axios from "../../../axiosInstance";
import API_BASE_URL from "../../../../apiRoute";


export const fetchDriverDetails = createAsyncThunk(
    'driverDetails/fetchDriverDetails',
    async function(driver_id) {
        console.log('asdf'
        )
        const accessToken = localStorage.getItem('accessToken');
        const response = await axios.get(`${API_BASE_URL}/users/driver/list/`, {
            params: { driver_id },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
        });

        const data = response.data;
        console.log('driverDetails:', data)
        return data;
    }
);
