// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
    max-width: 600px;
    margin: 40px auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    font-size: 18px;
    line-height: 1.6;
}

.title {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
}

.section {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    border-bottom: 1px solid #ddd;
}

.label {
    font-weight: bold;
    color: #555;
}

.loader {
    text-align: center;
    font-size: 22px;
    padding: 50px;
}
`, "",{"version":3,"sources":["webpack://./src/admin/components/DriverDetails/DriverDetails.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,aAAa;IACb,8BAA8B;IAC9B,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,eAAe;IACf,6BAA6B;AACjC;;AAEA;IACI,iBAAiB;IACjB,WAAW;AACf;;AAEA;IACI,kBAAkB;IAClB,eAAe;IACf,aAAa;AACjB","sourcesContent":[".container {\n    max-width: 600px;\n    margin: 40px auto;\n    padding: 20px;\n    font-family: Arial, sans-serif;\n    font-size: 18px;\n    line-height: 1.6;\n}\n\n.title {\n    font-size: 28px;\n    font-weight: bold;\n    margin-bottom: 20px;\n    text-align: center;\n}\n\n.section {\n    display: flex;\n    justify-content: space-between;\n    padding: 10px 0;\n    border-bottom: 1px solid #ddd;\n}\n\n.label {\n    font-weight: bold;\n    color: #555;\n}\n\n.loader {\n    text-align: center;\n    font-size: 22px;\n    padding: 50px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
