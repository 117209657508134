import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from '../../axiosInstance';
import API_BASE_URL from "../../../apiRoute";

export const fetchDriver = createAsyncThunk(
    'driver/fetchDriver',
    async function(driver_id) {
        const accessToken = localStorage.getItem('accessToken');
        const response = await axios.get(`${API_BASE_URL}/users/driver/list/`, {
            params: { driver_id },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
        });
        
        const data = response.data;
        return data;
    }
);

export const sendDriverSettings = createAsyncThunk(
    'driver/sendDriverSettings',
    async function({driver_id, percentage, hand_mode}) {

        const response = await axios.post(`https://api.diamondpay.am/api/driver`,
            {
                driver_id,
                percentage,
                hand_mode
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
        
        const data = response.data;
        return data;
    }
);


export const driverParks = createAsyncThunk(
    'driver/driverParks',
    async function (phone_number) {
        const response = await axios.post(
            `${API_BASE_URL}/park/parks_by_phone/`,
            { phone_number },
            {
                headers: {
                    'Content-Type': 'application/json',

                },
            }
        );
        const data = response.data;
        return data;
    }
);


export const updateDriver = createAsyncThunk(
    'driver/updateDriver',
    async function (query) {
        const response = await axios.get(
            `${API_BASE_URL}/api/search_driver/?query=${query}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
        const data = response.data;
        return data;
    }
);

export const fetchYandexDrivers = createAsyncThunk(
    'driver/fetchYandexDrivers',
    async function () {
        const response = await axios.post(
            `${API_BASE_URL}/utils/drivers/`,

                 {
                    park_id: '22f0784a005d4d28af14d93f24235c42',
                    client_id: 'taxi/park/22f0784a005d4d28af14d93f24235c42',
                    api_key: 'VgZLmfXxuFdeTlskkZQZKRxykICoRgaWDhskMU'
                },
            {
                    headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
        const data = response.data;
        return data;
    }
);
export const fetchActivePark = createAsyncThunk(
    'driver/fetchActivePark',
    async function (park_id) {
        const response = await axios.get(
            `${API_BASE_URL}/park/park/list/?park_id=${park_id}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
        const data = response.data;
        console.log(data[0])
        return data;
    }
);