/* eslint-disable react-hooks/exhaustive-deps */
import { Link } from 'react-router-dom';
import './MainInfo.css';
import { useEffect } from 'react';
import {fetchDriver, fetchYandexDrivers} from '../../../store/slices/deiverSlice/deiverAPI';
import {selectActivePark, selectDriver, selectDriverData} from '../../../store/slices/deiverSlice/deiverSlice';
import { useDispatch, useSelector } from 'react-redux';
import { selectDriversOrders } from '../../../store/slices/driverOrdersSlice/driverOrdersSlice';
import { fetchDriverOrders } from '../../../store/slices/driverOrdersSlice/driverOrdersAPI';
import { sendDriverTransfer } from '../../../store/slices/driverTransferSlice/driverTransferAPI.js';
import { selectLoginInput } from '../../../store/slices/loginInputSlice/loginInputSlice.js';
import { selectLanguage } from '../../../store/slices/languageSlice/LanguageSlice.js';
import { languageProvider } from '../../../assets/languageProvider.js';
import CircularProgress from '../../../sharedComponents/CircularProgres/CircularProgres.js';
import Logo from "../../../sharedComponents/Logo";

const MainInfo = ({newAmount, anim}) => {
    const driver = useSelector(selectDriverData);
    const dispatch = useDispatch()
    const driverOrders = useSelector(selectDriversOrders);
    const activeLanguage = useSelector(selectLanguage)
    const language = languageProvider({page: 'main', lang: activeLanguage})
    const park_id = localStorage.getItem('activePark')
    const driveInfo = localStorage.getItem('driverInfo')
    const active_park = useSelector(selectActivePark)
    useEffect(() => {
        const timestamp = localStorage.getItem("timestamp");
        const now = Date.now();
        if (timestamp && now - parseInt(timestamp) > 2 * 60 * 1000) {
            if (+park_id === 6){
                dispatch(fetchYandexDrivers())
            }
        }
        else if(!timestamp){
            dispatch(fetchYandexDrivers())
        }
        localStorage.setItem("timestamp", now);
    }, []);
    const successPercent = driver?.total_transfer_today
        ? Math.round((driver.total_transfer_today / 30000) * 100)
        : 0;
    const unSuccessMoney =driverOrders?.total
    ? driverOrders?.total - driverOrders?.ok_total
    : 0;
    const balance = driver?.balance
    ? driver?.balance
    :0;

    return (
        <div className='MainInfo'>
            <div className="MainInfoHeader">
                <div className="MainInfoHeaderBalance">
                    <span id='MainInfoHeading'>{language.balnace}</span>
                    <span className={'MainInfoMoney'}>֏{balance.toFixed(0)}.00</span>
                </div>
                <div className='MainInfoHeaderLogo'>
                    <Logo color={'#fff'} size={"50px"}/>
                </div>
            </div>
            <CircularProgress percent={successPercent} />
            <div className="SeeMoreContainer">
                <Link className='SeeMore' to='/orders'>{language.seeOrders}</Link>
            </div>
            <div className="MainInfoSecondary">
                <div className="MainInfoSent">
                    <span>{language.sent}</span>
                    <span className='SecondarySpan'>֏{driver?.total_transfer_today}</span>
                </div>
                <div className="MainInfoNotSent">
                    <span>Օրվա սահմանաչափ</span>
                    <span className='SecondarySpan'>֏{ active_park?.daily_limit - driver?.total_transfer_today }</span>
                </div>
            </div>
        </div>
    );
};

export default MainInfo;
