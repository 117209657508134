// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DriverHeader{
    background-color: var(--main);
    width: 100vw;
    height: 70px;
    border-bottom: 1px solid #ccc;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
}
.driver{
    color: black;
    font-size: 15px;
}
.settings-icon {
    width: 20px;
    height: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

@media only screen and (max-height:796px) {
    .DriverHeader{
        height: 35px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/driver/components/DriverHeader/DriverHeader.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;IAC7B,YAAY;IACZ,YAAY;IACZ,6BAA6B;IAC7B,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,eAAe;AACnB;AACA;IACI,YAAY;IACZ,eAAe;AACnB;AACA;IACI,WAAW;IACX,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,eAAe;AACnB;;AAEA;IACI;QACI,YAAY;IAChB;AACJ","sourcesContent":[".DriverHeader{\n    background-color: var(--main);\n    width: 100vw;\n    height: 70px;\n    border-bottom: 1px solid #ccc;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    padding: 0 10px;\n}\n.driver{\n    color: black;\n    font-size: 15px;\n}\n.settings-icon {\n    width: 20px;\n    height: 9px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    cursor: pointer;\n}\n\n@media only screen and (max-height:796px) {\n    .DriverHeader{\n        height: 35px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
