import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from '../../../axiosInstance';
import API_BASE_URL from "../../../../apiRoute";
import {getFirstDayOfMonth, getTomorrowDate} from "../../../../assets/dateFunctions";



export const fetchOrderTotals = createAsyncThunk(
    'dashboard/fetchOrderTotals',
    async function () {
        const accessToken = localStorage.getItem('accessToken'); // Retrieve access token from localStorage
        const park_id = localStorage.getItem('park_id');
        const response = await axios.post(
            `${API_BASE_URL}/park/total-price/`,
            {
                start_date: getFirstDayOfMonth(),
                end_date: getTomorrowDate(),

                park_id,
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`, // Add access token to Authorization header
                },
            }
        );

        return response.data;
    }
);

export const fetchActiveDrivers = createAsyncThunk(
    'dashboard/fetchActiveDrivers',
    async function () {
        const accessToken = localStorage.getItem('accessToken'); // Retrieve access token
        const park_id = localStorage.getItem('park_id');
        console.log(park_id)
        const response = await axios.post(
            `${API_BASE_URL}/park/active-drivers/`,
            {
                start_date: getFirstDayOfMonth(),
                end_date: getTomorrowDate(),
                park_id
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`, // Add access token
                },
            }
        );

        return response.data;
    }
);

export const fetchTransactionTotals = createAsyncThunk(
    'dashboard/fetchTransactionTotals',
    async function () {
        const accessToken = localStorage.getItem('accessToken'); // Retrieve access token
        const park_id = localStorage.getItem('park_id')
        const response = await axios.post(
            API_BASE_URL + '/park/transactions-info/',
            {
                start_date: getFirstDayOfMonth(),
                end_date: getTomorrowDate(),
                park_id,
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`, // Add access token
                },
            }
        );
        console.log(response.data)
        return response.data;
    }
);
