import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from '../../../axiosInstance';
import API_BASE_URL from "../../../../apiRoute";

export const fetchDriverList = createAsyncThunk(
    'driverList/fetchDriverList',
    async function({limit = 20, offset = 0, park_id}) {
        const accessToken = localStorage.getItem('accessToken'); // Retrieve access token from localStorage

        const response = await axios.get(
            `${API_BASE_URL}/users/driver/list/?limit=${limit}&offset=${offset}&park_id=${park_id}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}` // Add the access token to the Authorization header
                }
            }
        );

        const data = response.data;
        console.log('drivers', data)
        return data;
    }
);


export const updateDriver = createAsyncThunk(
    'driverList/updateDriver',
    async function ({ driver_id, newData }) {
        const accessToken = localStorage.getItem('accessToken'); // Get token from localStorage

        const response = await axios.patch(
            `${API_BASE_URL}/users/driver/update/${driver_id}/`,
            newData,
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`, // Include token
                },
            }
        );

        return response.data;
    }
);



export const searchDriver = createAsyncThunk(
    'driverList/searchDriver',
    async function ({ query }) {

        const accessToken = localStorage.getItem('accessToken'); // Retrieve access token from localStorage
        const park_id = localStorage.getItem('park_id');
        const response = await axios.get(
            `${API_BASE_URL}/api/search_driver/?query=${query}&park_id=${park_id}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}` // Add the access token to the Authorization header
                },
            }
        );

        const data = response.data;
        console.log(data)
        return data;
    }
);
export const searchDrivers_page = createAsyncThunk(
    'driverList/searchDrivers_page',
    async function ({ query }) {

        const accessToken = localStorage.getItem('accessToken'); // Retrieve access token from localStorage
        const park_id = localStorage.getItem('park_id');
        const response = await axios.get(
            `${API_BASE_URL}/api/search_driver/?query=${query}&park_id=${park_id}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}` // Add the access token to the Authorization header
                },
            }
        );

        const data = response.data;
        return data;
    }
);

function generateCSRFToken() {
    const array = new Uint8Array(32); // 32 bytes = 256 bits
    window.crypto.getRandomValues(array);
    return Array.from(array, byte => byte.toString(16).padStart(2, '0')).join('');
}
export const setDriverPassword = createAsyncThunk(
    'driverList/setDriverPassword',
    async function ({ phone_number, password }) {
        const response = await axios.post(
            `${API_BASE_URL}/users/generate_password/`,
            { phone_number, password },
            {
                headers: {
                    'Content-Type': 'application/json'
                },
            }
        );
        const data = response.data
        return data;
    }
);
