import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from '../../../axiosInstance';
import API_BASE_URL from "../../../../apiRoute";


export const fetchTransactionList = createAsyncThunk(
    'adminTransactionList/fetchAdminOrderList',
    async function ({limit = 20, offset = 0}) {
        const accessToken = localStorage.getItem('accessToken'); // Retrieve access token from localStorage
        const park_id = localStorage.getItem('park_id');
        const response = await axios.get(`${API_BASE_URL}/utils/transaction/list/?limit=${limit}&offset=${offset}&park_id=${park_id}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}` // Include the access token in the Authorization header
            }
        });
        console.log(response.data);
        return response.data;
    }
);
