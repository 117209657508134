import { Navigate, Outlet } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { checkSession } from "../store/slices/adminSlices/adminLoginSlice/adminLoginAPI";
import { selectAdminLoading, selectIsValid } from "../store/slices/adminSlices/adminLoginSlice/adminLoginSlice";
import Loading from "./Loading/Loading";

const ProtectedRoute = () => {
    const dispatch = useDispatch();
    const loading = useSelector(selectAdminLoading);
    const isValid = useSelector(selectIsValid);
    const [checked, setChecked] = useState(false);

    useEffect(() => {
        const fetchSession = async () => {
            const session = await dispatch(checkSession());
            setChecked(session?.payload?.Authorization);
        };

        fetchSession();
    }, [dispatch]);
    const token = localStorage.getItem('accessToken')
    // if (!checked || loading) return <Loading />; // Show loading while checking session
    if (!token) return <Navigate to="/admin/login" replace />; // Redirect if session is invalid
    return <Outlet />;
};

export default ProtectedRoute;
