import { createSlice } from "@reduxjs/toolkit";
import {fetchDriverDetails} from "./driverDetailsAPI";

const driverDetailsSlice = createSlice({
    name: 'driverDetails',
    initialState: {
        data: null
    },
    reducers:{
    },
    extraReducers: (builder) => {
        builder
        .addCase(fetchDriverDetails.fulfilled, (state, {payload}) => {
            return {
                ...state,
                data: payload[0]
            }
        })
    },
});
export const selectDriverDetails = (state) =>  state.driverDetails.data
export const driverDetailsReducer = driverDetailsSlice.reducer;