import React, { useEffect, useState } from 'react';
import './AntiFraud.css';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchAntiFraud,
    updateFraud,
    updateParkFraudSettings
} from '../../../store/slices/adminSlices/antiFraudSlice/antiFraudAPI';
import { selectAntiFraud } from '../../../store/slices/adminSlices/antiFraudSlice/antiFraudSlice';
import FraudChip from "./FraudChip";
import {fetchParkDetails} from "../../../store/slices/adminSlices/adminLoginSlice/adminLoginAPI";
import {selectAdminLogin} from "../../../store/slices/adminSlices/adminLoginSlice/adminLoginSlice";
import {toast} from "react-toastify";

const AntiFraud = () => {
    const park = useSelector(selectAdminLogin);
    const [settings, setSettings] = useState({
        order_price_min: park?.order_price_min || '',
        order_price_max: park?.order_price_max || '',
        route_distance_min: park?.route_distance_min || '',
        route_distance_max: park?.route_distance_max || '',
        route_time_min: park?.route_time_min || '',
        route_time_max: park?.route_time_max || '',
        has_fraud: false
    });
    const handleChange = (e) => {
        const { id, value } = e.target;
        setSettings((prevSettings) => ({
            ...prevSettings,
            [id]: value,
        }));
    };
    const initialState = {
        show: false,
        isLoading: false,
        orderPrice: 8989,
        driverName: '',
        fraud_id: 0
    }
    const [activeTab, setActiveTab] = useState('fraudList'); // State to manage active tab
    const [acceptModal, setAcceptModal] = useState(initialState);
    const [rejectModal, setRejectModal] = useState(initialState)
    const dispatch = useDispatch();
    const fraudData = useSelector(selectAntiFraud);
    // Fetch anti-fraud data on component mount
    const seeOrder = (id) => {
        window.open(`/admin/orders/order/${id}`, '_blank');
    }
    useEffect(() => {
        dispatch(fetchAntiFraud());
    }, [dispatch]);
    const applySettings = async () => {
        await dispatch(updateParkFraudSettings(settings))
        toast.success('Կարգավորումները թարմացված են։')
    }
    const updateDetails = async () => {
        const park = await dispatch(fetchParkDetails());
        const data = park.payload[0]
        setSettings({
            order_price_min: data?.order_price_min || '',
            order_price_max: data?.order_price_max || '',
            route_distance_min: data?.route_distance_min || '',
            route_distance_max: data?.route_distance_max || '',
            route_time_min: data?.route_time_min || '',
            route_time_max: data?.route_time_max || '',
            is_cancel: data?.is_cancel || '',
            is_fraud: data?.is_fraud || '',
            has_fraud: data?.has_fraud || false
        })
    }
    useEffect(() => {
        updateDetails()
    }, []);
    const handleAccept = (fraud) => {
        setAcceptModal({
            ...acceptModal,
            show: true,
            fraud_id: fraud.id,
            driverName: fraud.driver_name
        })
    };
    const acceptFraud = async (id) => {
        setAcceptModal({
            ...acceptModal,
            isLoading: true
        })
        await dispatch(updateFraud({id, payload: { status: 'approved' }}))
        setAcceptModal({
            ...acceptModal,
            show: false,
            isLoading: false
        })
        dispatch(fetchAntiFraud());
    }
    const handleReject = async (fraud) => {
        setRejectModal({
            ...acceptModal,
            show: true,
            fraud_id: fraud.id,
            driverName: fraud.driver_name
        })
    };
    const rejectFraud = async (id) => {
        setRejectModal({
            ...acceptModal,
            isLoading: true
        })
        await dispatch(updateFraud({id, payload: { status: 'rejected' }}))
        setRejectModal(initialState)
        dispatch(fetchAntiFraud());
    };

    return (
        <div className="anti-fraud-container">
            <h1 className="anti-fraud-title">Anti Fraud</h1>

            {/* Tab Navigation */}
            <div className="tab-navigation">
                <button
                    className={`tab-button ${activeTab === 'fraudList' ? 'active' : ''}`}
                    onClick={() => setActiveTab('fraudList')}
                >
                    Խարդախությունների ցանկ
                </button>
                <button
                    className={`tab-button ${activeTab === 'settings' ? 'active' : ''}`}
                    onClick={() => setActiveTab('settings')}
                >
                    Կարգավորումներ
                </button>
            </div>

            {/* Fraud List Tab */}
            {activeTab === 'fraudList' && (
                <div className="fraud-table-section">
                    <table className="fraudTable">
                        <thead>
                        <tr>
                            <th>Վարորդ</th>
                            <th>Խարդախության Տեսակ</th>
                            <th>Կարգավիճակ</th>
                            <th>Ստեղծման Ամսաթիվ</th>
                            <th>Պատվեր</th>
                            <th>Գործողություններ</th>
                        </tr>
                        </thead>
                        <tbody>
                        {fraudData?.map((fraud) => (
                            <tr key={fraud.id}>
                                <td>Անուն Ազգանուն Հայրանուն</td>
                                <td className="fraud-chips">{FraudChip(fraud.fraud_type)}</td>
                                <td>
                                            <span className={`status-badge ${fraud.status.toLowerCase()}`}>
                                                {
                                                    fraud.status === "pending" ? 'ընթացքում' :
                                                        fraud.status === "approved" ? "Խարդախություն" :
                                                            fraud.status === "rejected" ? 'Մերժված' : ''
                                                }
                                            </span>
                                </td>
                                <td>{new Date(fraud.created_at).toLocaleDateString()}</td>
                                <td className='fraud-order' onClick={() => seeOrder(fraud.order)}>Տեսնել Պատվերը <i
                                    className="fas fa-arrow-up-right-from-square"></i></td>
                                {/*{fraud.order}*/}
                                <td>
                                    {fraud.status === 'pending' && (
                                        <div className="action-buttons">
                                            <button
                                                className="accept-button"
                                                onClick={() => handleAccept(fraud)}
                                            >
                                                ✔
                                            </button>
                                            <button
                                                className="reject-button"
                                                onClick={() => handleReject(fraud)}
                                            >
                                                ✖
                                            </button>
                                        </div>
                                    )}
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            )}

            {/* Settings Tab */}
            {activeTab === 'settings' && (
                <div className="settings-section">
                    <div className="settings-form">
                        <div className="form-group">
                            <label htmlFor="lowPrice">Ցածր գին</label>
                            <input
                                type="number"
                                id="order_price_min"
                                value={settings.order_price_min}
                                onChange={handleChange}
                                placeholder="Նշեք ցածր գինը"
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="highPrice">Բարձր գին</label>
                            <input
                                type="number"
                                id="order_price_max"
                                value={settings.order_price_max}
                                onChange={handleChange}
                                placeholder="Նշեք բարձր գինը"
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="shortRoute">Կարճ երթուղի</label>
                            <input
                                type="number"
                                id="route_distance_min"
                                value={settings.route_distance_min}
                                onChange={handleChange}
                                placeholder="Նշեք կարճ երթուղին"
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="longRoute">Երկար երթուղի</label>
                            <input
                                type="number"
                                id="route_distance_max"
                                value={settings.route_distance_max}
                                onChange={handleChange}
                                placeholder="Նշեք երկար երթուղին"
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="shortTime">Կարճ ժամանակ</label>
                            <input
                                type="number"
                                id="route_time_min"
                                value={settings.route_time_min}
                                onChange={handleChange}
                                placeholder="Նշեք կարճ ժամանակը"
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="longTime">Երկար ժամանակ</label>
                            <input
                                type="number"
                                id="route_time_max"
                                value={settings.route_time_max}
                                onChange={handleChange}
                                placeholder="Նշեք երկար ժամանակը"
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="longTime">
                                Չեղարկված պատվերներ
                                <span className="tooltip">
                                    <i className="fa-regular fa-circle-question"></i>
                                    <span className="tooltiptext">
                                        Երբ վարորդը անընդմեջ չեղարկում է X
                                        քանակի գործարքներ, նա արգելափակվում է:
                                        Այս դաշտը սահմանում է չեղարկումների առավելագույն
                                        քանակը մինչև արգելափակումը:
                                    </span>
                                </span>
                            </label>
                            <input
                                type="number"
                                id="route_time_max"
                                value={settings.is_cancel}
                                onChange={handleChange}
                                placeholder="Չեղարկված պատվերներ"
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="longTime">
                                Խարդախությունների քանակ
                                <span className="tooltip">
                                    <i className="fa-regular fa-circle-question"></i>
                                    <span className="tooltiptext">
                                       Այս դաշտը սահմանում է
                                        խարդախությունների առավելագույն քանակը
                                        մինչև արգելափակումը:
                                    </span>
                                </span>
                            </label>
                            <input
                                type="number"
                                id="route_time_max"
                                value={settings.is_fraud}
                                onChange={handleChange}
                                placeholder="Խարդախությունների քանակ"
                            />
                        </div>
                        <div className='FraudSettingsApplyButtons'>
                            <button className="save-settings-button" onClick={applySettings}>Պահպանել կարգավորումները
                            </button>
                            {/*<button className="save-settings-button" onClick={applySettings}>*/}
                            {/*    {*/}
                            {/*        settings.has_fraud ? 'Անջատել' : 'Միացնել'*/}
                            {/*    }*/}
                            {/*</button>*/}
                        </div>
                    </div>
                </div>
            )}
            {
                acceptModal.show && (
                    <div className="driverModeChangeModal" onClick={() => setAcceptModal(initialState)}>
                        <div className="driverModeChangeModalContainer" onClick={(e) => e.stopPropagation()}>
                            {acceptModal.isLoading ? (
                                <div className={'modalSpinnerContainer'}>
                                    <div className="modalSpinner"></div>
                                </div>
                            ) : (
                                <>
                                    <p>Վստա՞հ եք, որ {acceptModal.driverName}-ն կատարել է խարդախություն։</p>
                                    <div className="driverModeChangeModalBtns">
                                        <button className="cancelBtn" onClick={() => setAcceptModal(initialState)}>Ոչ
                                        </button>
                                        <button className="applyBtn" onClick={() => acceptFraud(acceptModal.fraud_id)}>Այո
                                        </button>
                                    </div>
                                </>
                            )}

                        </div>
                    </div>)
            }
            {
                rejectModal.show && (
                    <div className="driverModeChangeModal" onClick={() => setRejectModal(initialState)}>
                        <div className="driverModeChangeModalContainer" onClick={(e) => e.stopPropagation()}>
                            {rejectModal.isLoading ? (
                                <div className={'modalSpinnerContainer'}>
                                    <div className="modalSpinner"></div>
                                </div>
                            ) : (
                                <>
                                    <p>Վստա՞հ եք, որ ցանկանում եք մերժել {rejectModal.driverName}-ի խարդախությունը։</p>
                                    <div className="driverModeChangeModalBtns">
                                        <button className="cancelBtn" onClick={() => setRejectModal(initialState)}>Ոչ
                                        </button>
                                        <button className="applyBtn"
                                                onClick={() => rejectFraud(rejectModal.fraud_id)}>Մերժել
                                        </button>
                                        <button className="applyBtn" onClick={() => setRejectModal(initialState)}>
                                            Մերժել և ապասառեցնել գումարը
                                        </button>

                                    </div>
                                </>
                            )}

                        </div>
                    </div>)
            }
        </div>
    );
};

export default AntiFraud;