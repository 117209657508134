import './Homewrap.css'
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import { Outlet } from 'react-router-dom';

function Homewrap() {
    return (
      <div className="homewrap">
        <Sidebar />
        <div className="main-layout">
          <Header />
          <div className="outlet-content">
            <Outlet />
          </div>
        </div>
      </div>
    );
  }
export default Homewrap;
