import { createSlice } from "@reduxjs/toolkit";
import {fetchDriverOrders, fetchDriverTransactions} from "./driverOrdersAPI";

const DriverOrdersSlice = createSlice({
    name: 'driverOrders',
    initialState: {
        driverOrders: null,
        driverTransactions: null,
    },
    reducers:{  
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchDriverOrders.fulfilled, (state, {payload}) => {
                return {
                    ...state,
                    driverOrders: payload
                };
            })
            .addCase(fetchDriverTransactions.fulfilled, (state, {payload}) => {
                return{
                    ...state,
                    driverTransactions: payload
                }
            })
    },
})

export const selectDriversOrders = state => state.driverOrders.driverTransactions

export const driverOrdersReducer = DriverOrdersSlice.reducer