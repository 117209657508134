import React, { useState } from 'react';
import { DatePicker, Space } from 'antd';
import 'antd/dist/reset.css'; 

const { RangePicker } = DatePicker;
const getNextDay = (dateString) => {
    // Create a new Date object from the provided date string
    const date = new Date(dateString);
    
    // Add one day to the date
    date.setDate(date.getDate() + 1);
    
    // Format the new date as YYYY-MM-DD
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const day = String(date.getDate()).padStart(2, '0');
    
    return `${year}-${month}-${day}`;
};

const DateRangePicker = ({filterInputs, setFilterInputs}) => {
    
    const handleDateChange = (dates, dateStrings) => {
        let nextDate = []
        if(dateStrings[1]){
            nextDate = [dateStrings[0], getNextDay(dateStrings[1])]
        }else{
            nextDate = [dateStrings[0]]
        }
    setFilterInputs({
        ...filterInputs,
        dateRangeDates: dates,
        dateRange: nextDate
    });

  };

  return (
    <Space direction="vertical" size={12}>
      <RangePicker value={filterInputs.dateRangeDates} onChange={handleDateChange} />
    </Space>
  );
};

export default DateRangePicker;
