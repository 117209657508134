import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from '../../../axiosInstance';
import API_BASE_URL from "../../../../apiRoute";

//81f90dbe007d4364ad88c56f77319695
export const sendAdminTransfer = createAsyncThunk(
    'adminTransfer/sendAdminTransfer',
    async function({price, driver_id}) {
        
        if (price > 0) {
            const response = await axios.post(`${API_BASE_URL}/utils/handle_transactions/`, {
                driver_id,
                price,
                origin: 'admin'
            }, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            
            const data = response.data;
            return data;
        }
    }
);

