import { useEffect, useState } from 'react';
import Logo from '../../../sharedComponents/Logo';
import './AdminLogin.css';
import { useDispatch, useSelector } from 'react-redux';
import {
    adminList,
    checkIsLoggedIn,
    checkSession,
    register,
    sendAdminPassword
} from '../../../store/slices/adminSlices/adminLoginSlice/adminLoginAPI';
import {
    selectAdminLoading,
    selectAdminLogin, selectIsValid,
    selectPasswordError
} from '../../../store/slices/adminSlices/adminLoginSlice/adminLoginSlice';
import { useNavigate } from 'react-router-dom';
import Loading from '../../../sharedComponents/Loading/Loading';
import {toast} from "react-toastify";

const AdminLogin = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let login
    const [loginInputs, setLoginInputs] = useState({ name: '', password: '' });
    const [passwordType, setPasswordType] = useState('password');


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setLoginInputs((prevInputs) => ({
            ...prevInputs,
            [name]: value,
        }));
    };

    const handleSubmit =  async (e) => {
        e.preventDefault();
        const adminLists = await dispatch(adminList(loginInputs.name))
        login = await dispatch(sendAdminPassword({ id: adminLists?.payload[0]?.id, password: loginInputs.password }))
        const session = await dispatch(checkSession())
        if(session?.payload?.Authorization && adminLists?.payload[0]?.id && localStorage.getItem('accessToken')) {
            navigate('/admin/dashboard')
        }else{
            toast.error("Սխալ ծածկանուն կամ ծածկագիր")
        }
    };



    const handlePassVisibility = ()=>{
        if (passwordType === 'password') setPasswordType('text')
        else setPasswordType('password')
    }

    useEffect(() => {
        if (localStorage.getItem('accessToken')){
            navigate('/admin/dashboard')
        }
    },[])
    return (
        <div className='AdminLogin'>
            <div className="AdminLoginSection">
                <div className="LoginSection">
                    <div className="MobileLogo">
                        {login?.error &&(
                            <h1>{login?.error?.message}</h1>
                        ) }
                        <h3>Բարի գալուստ</h3>
                    </div>
                    <div>
                        <h3>Մուտք գործեք </h3>
                        <h3 style={{display: "flex", alignItems: "center", gap: '5px'}}> Diamond Pay <Logo size='25px' color='#FF5C00'/></h3>
                    </div>
                    <form className='LoginSectionForm' onSubmit={handleSubmit}>
                        <input
                            type="text"
                            name="name"
                            placeholder='Ծածկանուն'
                            value={loginInputs.name}
                            onChange={handleInputChange}
                        />
                        <input
                            type="password"
                            name="password"
                            placeholder='Ծածկագիր'
                            value={loginInputs.password}
                            onChange={handleInputChange}
                        />
                        <button type="submit" className='LoginSubmitBtn'>Մուտք</button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AdminLogin;
