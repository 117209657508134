import { createSlice } from "@reduxjs/toolkit";
import { sendAdminTransfer } from "./adminTransferAPI";
import {toast} from "react-toastify";

const adminTransferSlice = createSlice({
    name: 'adminTransfer',
    initialState: {
        status: '',
    },
    reducers:{
    },
    extraReducers: (builder) => {
        builder
            .addCase(sendAdminTransfer.fulfilled, (state, {payload}) => {
                toast.success('Փոխանցումը կատարված է')
                return {
                    ...state,
                    status: 'success'
                }
            })
            .addCase(sendAdminTransfer.rejected, (state, {payload, error}) => {
                toast.error('Անբավարար միջոցներ')
                return {
                    ...state,
                    status: 'fail'
                }
            })

    },
});
export const selectAdminTransfer = (state) => state.adminTransfer
export const selectAdminTransferStatus = (state) => state.adminTransfer.status
export const {clearDrivers} = adminTransferSlice.actions
export const adminTransferReducer = adminTransferSlice.reducer;
