import { createSlice } from "@reduxjs/toolkit";
import {fetchTransactionList} from "./adminTransactionListAPI";

const adminTransactionList = createSlice({
    name: 'adminTransactionList',
    initialState: {
        data: [],
        singleTransaction: null
    },
    reducers:{},
    extraReducers: (builder) => {
        builder
            .addCase(fetchTransactionList.fulfilled, (state, {payload}) => {
                console.log(payload)
                return{
                    ...state,
                    data: payload
                }
            })
    },
});
export const selectAdminTransactionList = (state) => state.adminTransactionList.data
export const adminTransactionListReducer = adminTransactionList.reducer;
