import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { fetchDriverDetails } from "../../../store/slices/adminSlices/driverDetailsSlice/driverDetailsAPI";
import { useDispatch, useSelector } from "react-redux";
import { selectDriverDetails } from "../../../store/slices/adminSlices/driverDetailsSlice/driverDetailsSlice";
import './DriverDetails.css';

const DriverDetails = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const driver = useSelector(selectDriverDetails);

    useEffect(() => {
        dispatch(fetchDriverDetails(id));
    }, [dispatch, id]);

    if (!driver) return <div className="loader">Բեռնվում է...</div>;
    console.log(driver)
    return (
        <div className="container">
            <h1 className="title">Վարորդի Մանրամասները</h1>

            <div className="section">
                <span className="label">Անուն:</span>
                <span>{driver.driver_name}</span>
            </div>

            <div className="section">
                <span className="label">Հեռախոս:</span>
                <span>{driver.phone_number}</span>
            </div>

            <div className="section">
                <span className="label">Հաշվեկշիռ:</span>
                <span>{driver.balance} ֏</span>
            </div>

            <div className="section">
                <span className="label">Ակտիվ վարորդ:</span>
                <span>{driver.active_driver ? "✔️ Այո" : "❌ Ոչ"}</span>
            </div>

            <div className="section">
                <span className="label">Վերջին մուտք:</span>
                <span>{new Date(driver.last_login).toLocaleString('hy-AM')}</span>
            </div>

            <div className="section">
                <span className="label">Վերջին փոխանցում:</span>
                <span>{driver.last_transfer_date}</span>
            </div>
        </div>
    );
};

export default DriverDetails;
