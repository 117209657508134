import {Link, useNavigate} from 'react-router-dom';
import './Settings.css'
import { useDispatch, useSelector } from 'react-redux';
import {selectDriver, selectDriverData, startStopLoading} from '../../../store/slices/deiverSlice/deiverSlice';
import { driverParks, sendDriverSettings } from '../../../store/slices/deiverSlice/deiverAPI';
import { useEffect, useState } from 'react';
import { logout } from '../../../store/slices/loginInputSlice/loginInputAPI';
import { selectLanguage } from '../../../store/slices/languageSlice/LanguageSlice';
import { languageProvider } from '../../../assets/languageProvider';
import LanguageChange from '../../../sharedComponents/LanguageChange';
import { toast } from 'react-toastify';
import {handleIsValid} from "../../../store/slices/adminSlices/adminLoginSlice/adminLoginSlice";
import {updateDriver} from "../../../store/slices/adminSlices/adminDriverList/adminDriverListApi";
import {checkSession} from "../../../store/slices/adminSlices/adminLoginSlice/adminLoginAPI";

const Settings = () => {
    const driver = useSelector(selectDriverData)
    const driverPark = useSelector(selectDriver).parks
    const dispatch = useDispatch();
    const activeLanguage = useSelector(selectLanguage);
    const language = languageProvider({page: 'settings', lang: activeLanguage});
    const [settings, setSettings] = useState({
        park: driver?.park_id,
        hand_mode: driver?.auto_transfer ? 1 : 0 ,
        percentage: driver?.settings?.percentage || 0,
    });
    const navigate = useNavigate()
    const handleChange = async (e) =>  {
        const { name, value } = e.target;
        if (name === 'park'){
            let driverAccounts = JSON.parse(localStorage.getItem('driverAccounts'))
            let driverAccount = driverAccounts.find(element => element.id === value);
            localStorage.setItem('driverInfo', JSON.stringify(driverAccount.profile_id))
        }
        else if (name === 'hand_mode'){
            const updatedInfo = await dispatch(updateDriver({driver_id: driver?.id, newData: {auto_transfer: !settings.hand_mode}}))
            toast.success("Կարգավորումները հաջողությամբ փոփոխվեցին!");
            localStorage.setItem('driverInfo', JSON.stringify(updatedInfo?.payload.id));
        }
        setSettings((prevSettings) => ({
            ...prevSettings,
            [name]: value,
        }));
    };

    const handleLogout = async () => {
        localStorage.clear()
        dispatch(checkSession())
        navigate('/driver')
    };
    useEffect(() => {
        dispatch(driverParks(driver?.phone_number))
    }, []);
    return (
        <div className='Settings'>
            <div className="SettingsHeader">
                <Link to="/driver" className='HomeLink'><i className='fas fa-arrow-left'></i></Link>
                <span className='SettingsHeading'>{language.heading}</span>
            </div>
            <div className="MainSettings">
                <div className="Parks">
                    <div className="TransferSettingsHeadings">
                        <div className="SettingsIcon">
                            <i className="fa fa-taxi" aria-hidden="true"></i>
                        </div>
                        <span>{language.park}</span>
                    </div>
                    <select
                        name="park"
                        className='SettingsInputs'
                        value={settings.park}
                        onChange={handleChange}
                    >
                        {
                            driverPark?.map(el => {
                                return (
                                    <option key={el.id} value={el.id}>{el.name}</option>
                                )
                            })
                        }
                    </select>
                </div>
                <div className="ModeChange">
                    <div className="TransferSettingsHeadings">
                        <div className="SettingsIcon">
                            <i className="fa fa-exchange" aria-hidden="true"></i>
                        </div>
                        <span>{language.mode}</span>
                    </div>
                    <select 
                        name="hand_mode" 
                        className='SettingsInputs' 
                        value={settings.hand_mode} 
                        onChange={handleChange}
                    >
                        <option value={1}>{language.modeAuto}</option>
                        {(driver?.data?.park_id !== '22f0784a005d4d28af14d93f24235c42' ||
                            driver?.data?.park_id !== '847fa7a317424a4ab70fdb6f1fdbb34e')
                            &&
                            <option value={0}>{language.modeHand}</option>
                        }
                    </select>
                </div>
                {/* <div className="TransferPercent">
                    <div className="TransferSettingsHeadings">
                        <div className="SettingsIcon">
                            <i className="fa fa-percent" aria-hidden="true"></i>
                        </div>
                        <span>{language.percentage}</span>
                    </div>
                    <input 
                        type="number" 
                        name="percentage" 
                        value={settings.percentage}  
                        className='SettingsInputs' 
                        min='0' 
                        max='100' 
                        onChange={handleChange}
                    />
                </div> */}
            </div>
            <LanguageChange />
            <div className="DriverLogOut" onClick={handleLogout}>
                <i className="fa fa-sign-out" aria-hidden="true"></i>
                <span>{language.logout}</span>
            </div>
        </div>
    );
}

export default Settings;
