import React, { useEffect, useState } from 'react';
import './DriversPage.css';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Box, Select, MenuItem, FormControl, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchDriverList, searchDriver, searchDrivers_page,
    setDriverPassword,
    updateDriver
} from '../../../store/slices/adminSlices/adminDriverList/adminDriverListApi';
import { selectDriverList } from '../../../store/slices/adminSlices/adminDriverList/adminDriverList';
import { checkIsLoggedIn } from '../../../store/slices/adminSlices/adminLoginSlice/adminLoginAPI';
import { selectAdminLoading, selectAdminLogin } from '../../../store/slices/adminSlices/adminLoginSlice/adminLoginSlice';
import { useNavigate } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';

const DriversPage = () => {
  const dispatch = useDispatch();
  const driverList = useSelector(selectDriverList);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const park_id = localStorage.getItem('park_id')
  const [driverQuery, setDriverQuery] = useState('')
    const handleDriverQuery = (e) =>{
          setDriverQuery(e.target.value)
    }
  const [showModal, setShowModal] = useState({
      isModalLoading: false,
      isShow: false,
      driverName: '',
      driver_id: '',
      auto_transfer: false
  })
  const [percentModal, setPercentModal] = useState({
      isModalLoading: false,
      isShow: false,
      driver_id: 0,
      driverName: '',
      driverPercent: 0
  })
    const [generatePasswordModal, setGeneratePasswordModal] = useState({
        isShowModal: false,
        isModalLoading: false,
        isShowSureModal: false,
        phone_number: 0,
        driver_name: '',
        driverPassword: ''
    })
  const isSmallScreen = useMediaQuery('(max-width:600px)');
    function generatePassword() {
        const letters = 'abcdefghijklmnopqrstuvwxyz';
        let password = '';
        for (let i = 0; i < 6; i++) {
            password += letters[Math.floor(Math.random() * letters.length)];
        }
        return password;
    }
  const handleChangePage = (newPage) => {
    setPage(newPage);
  };
    const seeDriver = (id) => {
        // window.open(`/admin/drivers/${id}`, '_blank');
    }
    const handleShowPasswordModal = async (driver = null) => {
        if (driver){
            if (generatePasswordModal.isShowSureModal){
                let password = generatePassword()
                setGeneratePasswordModal({
                    isShowModal: true,
                    isModalLoading: false,
                    isShowSureModal: false,
                    phone_number: driver.phone_number,
                    driver_name: driver.driver_name,
                    driverPassword: password
                })
                await dispatch(setDriverPassword({phone_number: generatePasswordModal.phone_number, password: password}))
                dispatch(fetchDriverList({limit: rowsPerPage, offset: page * rowsPerPage, park_id: park_id, }));
            }else{
                setGeneratePasswordModal({
                    isShowModal: false,
                    isModalLoading: false,
                    isShowSureModal: true,
                    phone_number: driver.phone_number,
                    driver_name: driver.driver_name,
                    driverPassword: ''
                })
            }
        }else{

            setGeneratePasswordModal({
                isShowModal: false,
                isModalLoading: false,
                isShowSureModal: false,
                phone_number: 0,
                driver_name: '',
                driverPassword: ''
            })
        }
        }
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const paginatedData = driverList?.results;
  const numberOfPages = driverList?.total_pages;
    const handlePercentModalInput = ({ target }) => {
          if (target.value > 100 || target.value < 0 ){
              setPercentModal({
                  ...percentModal,
                  driverPercent: 100
              });
          }else{
              setPercentModal({
                  ...percentModal,
                  driverPercent: target.value
              });
          }

    };
  const handleShowPercentModal = (driver = null) =>{
          if (driver){
          setPercentModal({
              ...percentModal,
              driver_id: driver?.id,
              driverPercent: driver?.percentage * 100 || 0,
              driverName: driver?.driver_name,
              isShow: true
          })
      }else{
            setPercentModal({
                ...percentModal,
                isShow: false,
                isModalLoading: false,
                driverPercent: 0
            })
      }
  }
  const handleShowModal = (driver) =>{
      if (driver){
          setShowModal({
              ...showModal,
              isShow: !showModal.isShow,
              driverName: `${driver?.first_name} ${driver?.last_name}`,
              driver_id: driver?.id,
              auto_transfer: driver?.auto_transfer
          })
      }else{
          setShowModal({
              ...showModal,
              isShow: !showModal.isShow,
              isModalLoading: false
          })
      }
  }
  const handleDriverPercentChange = async (id) =>{
      if (percentModal.driverPercent <= 0 || percentModal.driverPercent > 100 || !percentModal.driverPercent ){
          return
      }
      setPercentModal({
          ...percentModal,
          isModalLoading: true
      })
      await dispatch(
          updateDriver(
              {
                  driver_id: id,
                  newData: {percentage: percentModal.driverPercent / 100}
              }
          )
      )
      handleShowPercentModal(null)
      dispatch(fetchDriverList({limit: rowsPerPage, offset: page * rowsPerPage, park_id: park_id}));

  }
  const handleDriverModeChange = async (id) => {
      setShowModal({
          ...showModal,
          isModalLoading: true
      })
     await dispatch(
          updateDriver(
              {
                  driver_id: id,
                  newData: {auto_transfer: !showModal.auto_transfer}
              }
          )
      )
      handleShowModal(null)
      dispatch(fetchDriverList({limit: rowsPerPage, offset: page * rowsPerPage, park_id: park_id}));

    }
    useEffect(() => {
        if (!driverQuery){
          dispatch(fetchDriverList({limit: rowsPerPage, offset: page * rowsPerPage, park_id: park_id}));
        }
    }, [page, rowsPerPage, driverQuery]);

  const renderPagination = () => {
    const pagesToShow = isSmallScreen ? 1: 4; // Show fewer pages on small screens
    const startPage = Math.max(0, page - Math.floor(pagesToShow / 2));
    const endPage = Math.min(startPage + pagesToShow - 1, numberOfPages - 1);
  
    const pages = [];
  
    if (startPage > 0) {
      pages.push(
        <Button key={0} onClick={() => handleChangePage(0)} variant="outlined">
          1
        </Button>,
        startPage > 1 && <Typography key="start-ellipsis" variant="body2">...</Typography>
      );
    }
  
    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <Button
          key={i}
          onClick={() => handleChangePage(i)}
          variant="outlined"
          sx={{
            mx: 0.5,
            fontSize: isSmallScreen ? '12px' : 'inherit', // Smaller font size on small screens
            color: i === page ? 'white' : 'inherit',
            borderColor: i === page ? '#FF5C00' : 'black',
            backgroundColor: i === page ? '#FF5C00' : 'transparent',
          }}
        >
          {i + 1}
        </Button>
      );
    }
  
    if (endPage < numberOfPages - 1) {
      pages.push(
        endPage < numberOfPages - 2 && <Typography key="end-ellipsis" variant="body2">...</Typography>,
        <Button key={numberOfPages - 1} onClick={() => handleChangePage(numberOfPages - 1)} variant="outlined">
          {numberOfPages}
        </Button>
      );
    }
  
    return pages;
  };
  const searchDrivers = () =>{
      dispatch(searchDrivers_page({query: driverQuery}))
  }
  return (

      <>
          <div className="driverPageHeading">
              <h1>Վարորդներ</h1>
              <div className="searchContainer">
                  <i className={`fas fa-search searchIcon ${driverQuery ? 'activeSearchIcon' : ''}`} onClick={searchDrivers}></i>
                  <input
                      className="driverSearchInput"
                      placeholder="Որոնել"
                      type="text"
                      value={driverQuery}
                      onChange={(e) => setDriverQuery(e.target.value)}
                  />
              </div>
          </div>
          <TableContainer component={Paper} className="TableContainer">
              <Box display="flex" alignItems="center" justifyContent="space-between" px={2} mt={2}>
                  {!isSmallScreen && ( // Conditionally render this part based on screen size
                      <Box display="flex" alignItems="center">
                          <Typography variant="body1" sx={{marginRight: 1}}>Ցուցադրվող քանակ:</Typography>
                          <FormControl variant="outlined" size="small">
                              <Select value={rowsPerPage} onChange={handleChangeRowsPerPage}>
                                  <MenuItem value={10}>10</MenuItem>
                                  <MenuItem value={20}>20</MenuItem>
                                  <MenuItem value={50}>50</MenuItem>
                                  <MenuItem value={100}>100</MenuItem>
                              </Select>
                          </FormControl>
                      </Box>
                  )}
                  <Box display="flex" alignItems="center" justifyContent="center" flexWrap="nowrap">
                      <Button onClick={() => handleChangePage(page - 1)} disabled={page === 0}
                              sx={{color: '#FF5C00'}}>&lt;</Button>
                      {renderPagination()}
                      <Button onClick={() => handleChangePage(page + 1)} disabled={page >= numberOfPages - 1}
                              sx={{color: '#FF5C00'}}>&gt;</Button>
                  </Box>
              </Box>

              {isSmallScreen ? (
                  <div>
                      <div className="CardContainer">
                          {paginatedData?.map((driver, index) => (
                              <div key={index} className="DriverCard">
                                  <h3>{`${driver.first_name} ${driver.last_name}`}</h3>
                                  <div className="DriverCardInfo">
                                      <div className="DriverCardItem">
                                          <span className="DriverCardItemLabel">Balance:</span>
                                          <span>{driver.balance.toFixed(0)}</span>
                                      </div>
                                      <div className="DriverCardItem">
                                          <span className="DriverCardItemLabel">Idram:</span>
                                          <span>{driver.idram}</span>
                                      </div>
                                      <div className="DriverCardItem">
                                          <span className="DriverCardItemLabel">Percentage:</span>
                                          <span>{driver.percentage * 100}%</span>
                                      </div>
                                      <div className="DriverCardItem">
                                          <span className="DriverCardItemLabel">Phone:</span>
                                          <span>{driver.phone_number}</span>
                                      </div>
                                      <div className="DriverCardItem">
                                          <span className="DriverCardItemLabel">Mode:</span>
                                          <span>{driver.transfer_allowed ? "Auto" : "Manual"}</span>
                                      </div>
                                  </div>
                              </div>
                          ))}
                      </div>
                  </div>
              ) : (
                  <>

                      <Table>
                          <TableHead>
                              <TableRow>
                                  <TableCell>Անուն</TableCell>
                                  <TableCell>Բալանս</TableCell>
                                  <TableCell>Idram</TableCell>
                                  <TableCell>Տոկոս</TableCell>
                                  <TableCell>Հեռ․համար</TableCell>
                                  <TableCell>Ռեժիմ</TableCell>
                              </TableRow>
                          </TableHead>
                          <TableBody>
                              {paginatedData?.map((driver, index) => (
                                  <TableRow
                                      key={index}
                                      style={{
                                          backgroundColor: driver?.super_active_driver ? '#fff0e6' : 'transparent',  // Light yellow background for active drivers
                                      }}
                                      onClick={() => seeDriver(driver.id)}
                                  >
                                      <TableCell>
                                          {driver?.super_active_driver && (
                                              <i
                                                  className="fa fa-bolt"
                                                  style={{color: 'orange', fontSize: '18px', marginRight: '10px'}}
                                              ></i>
                                          )}
                                          {`${driver?.first_name} ${driver?.last_name} ${driver?.middle_name}`}
                                      </TableCell>
                                      <TableCell>{driver.balance.toFixed(0)}</TableCell>
                                      <TableCell>{driver.idram}</TableCell>
                                      <TableCell>
                                <span className='driverModeTableCell'>
                                  {driver.percentage * 100}%
                                  <i className="fa-solid fa-sliders" onClick={() => handleShowPercentModal(driver)}></i>
                                </span>
                                      </TableCell>
                                      <TableCell>{driver.phone_number}</TableCell>
                                      <TableCell>
                                <span className='driverModeTableCell'>
                                    <span className='driverModeSpan'>
                                      {driver.auto_transfer ? "Ավտոմատ" : "Ձեռքով"}
                                    </span>
                                    <i className="fas fa-rotate" onClick={() => handleShowModal(driver)}></i>
                                    {
                                        !driver.password ? (
                                            <i className="fa fa-key"
                                               onClick={() => handleShowPasswordModal(driver)}></i>
                                        ) : (
                                            <i className="fas fa-rotate-right"
                                               onClick={() => handleShowPasswordModal(driver)}></i>
                                        )
                                    }
                                </span>
                                      </TableCell>
                                  </TableRow>
                              ))}
                          </TableBody>

                      </Table>
                  </>
              )}
              <Box display="flex" alignItems="center" justifyContent="center" mt={2}>
                  <Button onClick={() => handleChangePage(page - 1)} disabled={page === 0}
                          sx={{color: '#FF5C00'}}>&lt;</Button>
                  {renderPagination()}
                  <Button onClick={() => handleChangePage(page + 1)} disabled={page >= numberOfPages - 1}
                          sx={{color: '#FF5C00'}}>&gt;</Button>
              </Box>
          </TableContainer>
          {
              showModal.isShow && (
                  <div className="driverModeChangeModal" onClick={() => handleShowModal(null)}>
                      <div className="driverModeChangeModalContainer" onClick={(e) => e.stopPropagation()}>
                          {showModal.isModalLoading ? (
                              <div className={'modalSpinnerContainer'}>
                                  <div className="modalSpinner"></div>
                              </div>
                          ) : (
                              <>
                                  <p>Վստա՞հ եք, որ ցանկանում եք փոխել {showModal.driverName}-ի փոխանցման ռեժիմը:</p>
                                  <div className="driverModeChangeModalBtns">
                                      <button className="cancelBtn" onClick={() => handleShowModal(null)}>Չեղարկել</button>
                                      <button className="applyBtn"
                                              onClick={() => handleDriverModeChange(showModal.driver_id)}>Հաստատել
                                      </button>
                                  </div>
                              </>
                          )}

                      </div>
                  </div>

              )
          }
          {
              percentModal.isShow && (
                  <div className="driverModeChangeModal" onClick={() => handleShowPercentModal(null)}>
                      <div className="driverModeChangeModalContainer" onClick={(e) => e.stopPropagation()}>
                          {percentModal.isModalLoading ? (
                              <div className={'modalSpinnerContainer'}>
                                  <div className="modalSpinner"></div>
                              </div>
                          ) : (
                              <>
                                  <div>
                                      <p>Խմբագրեք վարորդի տոկոսը</p>
                                      <div className={'percentModalInputContainer'}>
                                          <input
                                              className={'percentModalInput'}
                                              type="number"
                                              value={percentModal.driverPercent}
                                              onChange={(e) => handlePercentModalInput(e)}
                                          />
                                          <span>%</span>
                                      </div>
                                  </div>
                                  <div className="driverModeChangeModalBtns">
                                      <button className="cancelBtn" onClick={() => handleShowPercentModal(null)}>Չեղարկել
                                      </button>
                                      <button className="applyBtn"
                                              onClick={() => handleDriverPercentChange(percentModal.driver_id)}>Հաստատել
                                      </button>
                                  </div>
                              </>
                          )}

                      </div>
                  </div>
              )
          }
          {
              generatePasswordModal.isShowSureModal && (
                  <div className="driverModeChangeModal" onClick={() => handleShowPasswordModal(null)}>
                      <div className="driverModeChangeModalContainer" onClick={(e) => e.stopPropagation()}>
                          {percentModal.isModalLoading ? (
                              <div className={'modalSpinnerContainer'}>
                                  <div className="modalSpinner"></div>
                              </div>
                          ) : (
                              <>
                                  <div>
                                      <p> Վստա՞հ եք, որ ցանկանում եք ստեղծել նոր
                                          գաղտնաբառ {generatePasswordModal.driver_name}-ի համար։</p>

                                  </div>
                                  <div className="driverModeChangeModalBtns">
                                      <button className="cancelBtn" onClick={() => handleShowPasswordModal(null)}>Չեղարկել
                                      </button>
                                      <button className="applyBtn"
                                              onClick={() => handleShowPasswordModal(generatePasswordModal)}>Այո, ստեղծել
                                      </button>
                                  </div>
                              </>
                          )}

                      </div>
                  </div>
              )
          }
          {
              generatePasswordModal.isShowModal && (
                  <div className="driverModeChangeModal" onClick={() => handleShowPasswordModal(null)}>
                      <div className="driverModeChangeModalContainer" onClick={(e) => e.stopPropagation()}>
                          {percentModal.isModalLoading ? (
                              <div className={'modalSpinnerContainer'}>
                                  <div className="modalSpinner"></div>
                              </div>
                          ) : (
                              <>
                                  <div>
                                      <p>Գաղտնաբառը հաջողությամբ ստեղծվել է։</p>
                                      <p>{generatePasswordModal.driverPassword}</p>
                                  </div>
                                  <div className="driverModeChangeModalBtns">
                                      <button className="cancelBtn" onClick={() => handleShowPasswordModal(null)}>Փակել
                                      </button>
                                  </div>
                              </>
                          )}

                      </div>
                  </div>
              )
          }
      </>

  );
};

export default DriversPage;
