import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Paper } from '@mui/material';

const LiveChart = ({ chartData = [] }) => {
  const formatYAxisTicks = (value) => {
    return value >= 1000000 ? `${(value / 1000000).toFixed(1)}M` : value.toString();
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
          <div style={{
            backgroundColor: '#fff',
            padding: '10px',
            border: '1px solid #ddd',
            borderRadius: '8px',
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
          }}>
            <p style={{ margin: 0, color: '#333', fontWeight: 'bold' }}>{label}</p>
            <p style={{ margin: 0, color: '#FF5C00' }}>
              <strong>Sum:</strong> {payload[0].value.toLocaleString()}
            </p>
          </div>
      );
    }
    return null;
  };

  return (
      <Paper sx={{marginTop: '30px', padding: 0, border: 'none', width: '100%', height: '100%' }}>
        <ResponsiveContainer width="100%" height={400}>
          <LineChart data={chartData} margin={{ top: 60, right: 30, left: 50, bottom: 30 }}>
            {/* Title and Subtitle */}
            <text x="50%" y={20} textAnchor="middle" dominantBaseline="hanging" style={{ fontSize: '18px', fontWeight: 'bold', fill: '#333' }}>
              Գործարքներ
            </text>
            <text x="50%" y={40} textAnchor="middle" dominantBaseline="hanging" style={{ fontSize: '14px', fill: '#666' }}>
              Գործարքների օրական գումարը
            </text>

            {/* Grid and Axes */}
            <CartesianGrid strokeDasharray="3 3" stroke="#eee" />
            <XAxis
                dataKey="date"
                label={{ value: "Ամսաթիվ", position: "insideBottomRight", offset: -20, fill: '#666' }}
                tick={{ fontSize: 12, fill: '#666' }}
                axisLine={{ stroke: '#ddd' }}
                tickLine={{ stroke: '#ddd' }}
            />
            <YAxis
                domain={[0, (dataMax) => dataMax + 100000]}
                label={{ value: "Գումար", angle: -90, position: "insideLeft", offset: -20, fill: '#666' }}
                tickFormatter={formatYAxisTicks}
                tick={{ fontSize: 12, fill: '#666' }}
                axisLine={{ stroke: '#ddd' }}
                tickLine={{ stroke: '#ddd' }}
            />

            {/* Tooltip */}
            <Tooltip content={<CustomTooltip />} />

            {/* Legend */}
            <Legend
                align="right"
                verticalAlign="top"
                iconType="circle"
                iconSize={10}
                formatter={(value) => <span style={{ color: '#333', fontWeight: '500' }}>{value}</span>}
                wrapperStyle={{ paddingTop: '10px' }}
            />

            {/* Line */}
            <Line
                type="monotone"
                dataKey="sum"
                stroke="#FF5C00"
                strokeWidth={3}
                dot={{ fill: '#FF5C00', strokeWidth: 2, r: 5 }}
                isAnimationActive={true}
                animationDuration={1000}
                activeDot={{ r: 8, fill: '#FF5C00', stroke: '#fff', strokeWidth: 2 }}
            />
          </LineChart>
        </ResponsiveContainer>
      </Paper>
  );
};

export default LiveChart;