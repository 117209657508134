import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './Transfer.css';
import { sendDriverTransfer } from '../../../store/slices/driverTransferSlice/driverTransferAPI';
import {selectDriverData} from '../../../store/slices/deiverSlice/deiverSlice';
import { selectLanguage } from '../../../store/slices/languageSlice/LanguageSlice';
import { languageProvider } from '../../../assets/languageProvider';
import { selectDriverTransfer } from '../../../store/slices/driverTransferSlice/driverTransferSlice';
import {toast} from "react-toastify";
import {fetchDriver} from "../../../store/slices/deiverSlice/deiverAPI";

const Transfer = ({setNewAmount, setAnim}) => {
    const dispatch = useDispatch();
    const [amount, setAmount] = useState('');
    const [count, setCount] = useState(0);

    const driver_id = JSON.parse(localStorage.getItem('driverInfo'));
    const driver = useSelector(selectDriverData);
    const activeLanguage = useSelector(selectLanguage)
    const language = languageProvider({page: 'main', lang: activeLanguage})
    const driverTransfer = useSelector(selectDriverTransfer)

    const handleTransfer = () => {
        if (count === 0) {
            setAmount('');
            startCountdown(59, setCount);
            dispatch(sendDriverTransfer({ price: amount, driver_id: driver?.profile_id }))
                .then((result) => {
                    if (result.meta.requestStatus === "fulfilled") {
                        dispatch(fetchDriver(driver_id));
                    }
                });
        }
    };
    useEffect(() => {
        // setNewAmount(driverTransfer?.amount)
        if (driverTransfer?.status === 'ok'){
            toast.success(`${driverTransfer?.amount} դրամը փոխանցված է`, {
                autoClose: 2000,
            } );
        }
    }, [driverTransfer]);
    const startCountdown = (start, updateState) => {
        let count = start;
      
        const timer = setInterval(() => {
          count -= 1;
          updateState(count); 
      
          if (count <= 0) {
            clearInterval(timer); 
          }
        }, 1000);
      };
      const [displayCount, setDisplayCount] = useState('')
      useEffect(() => {
        if(count < 10 ){
            setDisplayCount(`0${count}`)
        }else{
            setDisplayCount(`${count}`)
            
        }
      }, [count]);
      useEffect(() => {
        if(count === 0){
            setDisplayCount('')
        }
      }, [])
    return (
        <div className='Transfer'>
            <div className="TransferHeading">
                <span>{language.sendIdram}</span>
            </div>
            <div className="TransferContainer">
                <div className="balance">
                    <input 
                        type="number" 
                        className='balanceInput' 
                        max='999999' 
                        min='0' 
                        value={amount} 
                        onChange={(e) => setAmount(e.target.value)} 
                    />
                    <span>֏</span>
                </div>
                <div className="transferButton">
                    <button onClick={handleTransfer}>{count ? `00:${displayCount}` : language.send}</button>
                    {/*<button onClick={handleTransfer}>{language.send}</button>*/}
                </div>
            </div>
        </div>
    );
}

export default Transfer;
