import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from '../../axiosInstance';
import API_BASE_URL from "../../../apiRoute";

export const sendDriverTransfer = createAsyncThunk(
    'driverTransfer/sendDriverTransfer',
    async function({ price, driver_id }) {
        const accessToken = localStorage.getItem('accessToken'); // Retrieve token from localStorage

        const response = await axios.post(
            `${API_BASE_URL}/utils/handle_transactions/`,
            {
                driver_id,
                price,
                origin: 'driver'
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}` // Include token
                }
            }
        );

        const data = response.data;
        return data;
    }
);
