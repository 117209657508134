/* eslint-disable react-hooks/exhaustive-deps */
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Settings from './components/Settings';
import './driverApp.css'
import Login from './components/Login/Login';
import DriverOrderList from './components/DriverOrderList';
import { useDispatch, useSelector } from 'react-redux';
import {fetchDriver} from '../store/slices/deiverSlice/deiverAPI';
import { useEffect } from 'react';
import Loading from '../sharedComponents/Loading/Loading';
import DriverHome from "./components/DriverHome/DriverHome";
import DriverProtectedRoute from "../sharedComponents/DriverProtectedRoute";
import {selectDriverLoading} from "../store/slices/loginInputSlice/loginInputSlice";


function DriverApp() {
  const driverLoading = useSelector(selectDriverLoading)
  const dispatch = useDispatch();
    useEffect(() => {
        let driverInfo = localStorage.getItem('driverInfo')
        if (driverInfo){
            dispatch(fetchDriver(JSON.parse(driverInfo)))
        }
    },[])
  return (
    <div className="DriverApp">
      <Loading show={driverLoading} />
      <Routes>
          <Route path='/driver/login' element={<Login/>} />
          <Route element={<DriverProtectedRoute/>}>
            <Route path='/driver' element={<DriverHome/>} />
          </Route>
          <Route element={<DriverProtectedRoute/>}>
              <Route path='/settings' element={<Settings />} />
          </Route>
          <Route element={<DriverProtectedRoute/>}>
              <Route path='/orders' element={<DriverOrderList/>} />
          </Route>
      </Routes>
    </div>
  );
}

export default DriverApp;