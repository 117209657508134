// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../node_modules/@fortawesome/fontawesome-free/css/all.min.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+Armenian:wght@100..900&display=swap);"]);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Noto Sans Armenian", sans-serif;
}

::-webkit-scrollbar {
  width: 5px; 
}
::-webkit-scrollbar-thumb {
  background-color: #FF5C00; 
  border-radius: 10px;
}
:root{--main: #ffffff;}
:root{--primary: #ff5c00;}
:root{--secondary: #008C8C;}
:root{--success: #52be4f}
:root{--fail: #ef3e44}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAEA;EACE,UAAU;EACV,SAAS;EACT,sBAAsB;EACtB,6CAA6C;AAC/C;;AAEA;EACE,UAAU;AACZ;AACA;EACE,yBAAyB;EACzB,mBAAmB;AACrB;AACA,MAAM,eAAe,CAAC;AACtB,MAAM,kBAAkB,CAAC;AACzB,MAAM,oBAAoB,CAAC;AAC3B,MAAM,kBAAkB;AACxB,MAAM,eAAe","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Armenian:wght@100..900&display=swap');\n@import '@fortawesome/fontawesome-free/css/all.min.css';\n*{\n  padding: 0;\n  margin: 0;\n  box-sizing: border-box;\n  font-family: \"Noto Sans Armenian\", sans-serif;\n}\n\n::-webkit-scrollbar {\n  width: 5px; \n}\n::-webkit-scrollbar-thumb {\n  background-color: #FF5C00; \n  border-radius: 10px;\n}\n:root{--main: #ffffff;}\n:root{--primary: #ff5c00;}\n:root{--secondary: #008C8C;}\n:root{--success: #52be4f}\n:root{--fail: #ef3e44}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
