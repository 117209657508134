import { Chip, Stack } from "@mui/material";

const fraudTypes = {
    "слишком низкая цена": { label: "Չափազանց ցածր գին", color: "error", icon: "🏷️" },
    "слишком высокая цена": { label: "Չափազանց բարձր գին", color: "warning", icon: "💰" },
    "слишком короткий маршрут": { label: "Չափազանց կարճ երթուղի", color: "info", icon: "📍" },
    "слишком длинный маршрут": { label: "Չափազանց երկար երթուղի", color: "primary", icon: "🗺️" },
    "слишком короткое время": { label: "Չափազանց կարճ ժամանակ", color: "success", icon: "⏳" },
    "слишком длинное время": { label: "Չափազանց երկար ժամանակ", color: "secondary", icon: "⏱️" },
};

const FraudChip = (fraudTypesArray) => {
    let stringData = fraudTypesArray;
    let validArray = JSON.parse(stringData.replace(/'/g, '"'));
    return (
        <Stack direction="column" mb={'10px'} gap={1} > {/* Add gap here */}
            {validArray?.map((type, index) => {
                const trimmedType = type.trim();
                const fraud = fraudTypes[trimmedType] || { label: "Անհայտ", color: "default", icon: "❓" };
                return (
                    <Chip
                        key={index}
                        label={`${fraud.icon} ${fraud.label}`}
                        color={fraud.color}
                        variant="outlined"
                        sx={{ fontWeight: "bold", fontSize: "14px" }}
                    />
                );
            })}
        </Stack>
    );
};

export default FraudChip;