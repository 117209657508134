import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Box, Select, MenuItem, FormControl, Typography, useMediaQuery } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { sendOrderTransfer } from '../../../store/slices/adminSlices/adminOrderList/adminOrderAPI';
import PageHeader from '../PageHeader';
import { checkIsLoggedIn } from '../../../store/slices/adminSlices/adminLoginSlice/adminLoginAPI';
import { useNavigate } from 'react-router-dom';
import { selectAdminLoading, selectAdminLogin } from '../../../store/slices/adminSlices/adminLoginSlice/adminLoginSlice';
import { toast } from 'react-toastify'
import React, {useState, useEffect} from "react";
import {selectAdminTransactionList} from "../../../store/slices/adminSlices/adminTransactionList/adminTransactionList";
import './AdminOrders.css'
import {fetchTransactionList} from "../../../store/slices/adminSlices/adminTransactionList/adminTransactionListAPI";


const AdminOrders = () => {
    const dispatch = useDispatch();
    const transactions = useSelector(selectAdminTransactionList);
    const data = transactions?.results || [];
    const navigate = useNavigate()
    const isSmallScreen = useMediaQuery('(max-width:600px)');
    const [filterValues, setFilterValues] = useState({
        dateRange: [],
        dateRangeDates: [],
        status: [],
        inputVal: '',
        driver_id: ''
    });
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);

    function formatTimestamp(timestamp) {
        // Parse the timestamp into a JavaScript Date object
        const date = new Date(timestamp);

        // Format the date parts
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const year = date.getFullYear();

        // Format the time parts
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');

        // Return the formatted string
        return `${day}-${month}-${year}/${hours}:${minutes}`;
    }
    const handleStatus = (status) => {
        switch (status) {
            case 'completed':
                return 'statusCompleted'
            case 'limit exceeded':
                return 'statusLimit_exceeded'
            case 'pending':
                return 'statusPending'
            case 'not enough balance':
                return 'statusNot_enough_balance'
            default:
                return  'unknownStatus'
        }
    }
    const statusName = (status) => {
        switch (status) {
            case 'completed':
                return 'Փոխանցված'
            case 'limit exceeded':
                return 'Սահմանաչափը գերազանցված է'
            case 'pending':
                return 'Ընթացքում'
            case 'not enough balance':
                return 'Անբավարար միջոցներ'
            default:
                return 'Անհայտ խնդիր'
        }
    }
    const handleChangePage = (newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const paginatedData = data;
    const numberOfPages = transactions?.total_pages;

    useEffect(() => {
        dispatch(fetchTransactionList({limit: rowsPerPage, offset: page * rowsPerPage}))
    }, [page, rowsPerPage]);



    const renderPagination = () => {
        const pagesToShow = isSmallScreen ? 1 : 4; // Show fewer pages on small screens
        const startPage = Math.max(0, page - Math.floor(pagesToShow / 2));
        const endPage = Math.min(startPage + pagesToShow - 1, numberOfPages - 1);

        const pages = [];

        if (startPage > 0) {
            pages.push(
                <Button key={0} onClick={() => handleChangePage(0)} variant="outlined">
                    1
                </Button>,
                startPage > 1 && <Typography key="start-ellipsis" variant="body2">...</Typography>
            );
        }

        for (let i = startPage; i <= endPage; i++) {
            pages.push(
                <Button
                    key={i}
                    onClick={() => handleChangePage(i)}
                    variant="outlined"
                    sx={{
                        mx: 0.5,
                        fontSize: isSmallScreen ? '12px' : 'inherit', // Smaller font size on small screens
                        color: i === page ? 'white' : 'inherit',
                        borderColor: i === page ? '#FF5C00' : 'black',
                        backgroundColor: i === page ? '#FF5C00' : 'transparent',
                    }}
                >
                    {i + 1}
                </Button>
            );
        }

        if (endPage < numberOfPages - 1) {
            pages.push(
                endPage < numberOfPages - 2 && <Typography key="end-ellipsis" variant="body2">...</Typography>,
                <Button key={numberOfPages - 1} onClick={() => handleChangePage(numberOfPages - 1)} variant="outlined">
                    {numberOfPages}
                </Button>
            );
        }

        return pages;
    };

    function formatDateString(dateString) {
        const date = new Date(dateString);
        const day = String(date.getUTCDate()).padStart(2, '0');
        const month = String(date.getUTCMonth() + 1).padStart(2, '0');
        const year = date.getUTCFullYear();
        const hours = String(date.getUTCHours()).padStart(2, '0');
        const minutes = String(date.getUTCMinutes()).padStart(2, '0');
        return `${day}-${month}-${year}/${hours}:${minutes}`;
    }

    const navigateToOrder = (id) =>{
        navigate(`order/${id}`)
    }
    return (
        <>
            <PageHeader
                heading={'Գործարքներ'}
                page={'transactions'}
                filterValues={filterValues}
                setFilterValues={setFilterValues}
            />
            <TableContainer component={Paper}>
                <Box display="flex" alignItems="center" justifyContent="space-between" px={2} mt={2}>
                    {!isSmallScreen && ( // Conditionally render this part based on screen size
                        <Box display="flex" alignItems="center">
                            <Typography variant="body1" sx={{ marginRight: 1 }}>Ցուցադրվող քանակ:</Typography>
                            <FormControl variant="outlined" size="small">
                                <Select value={rowsPerPage} onChange={handleChangeRowsPerPage}>
                                    <MenuItem value={10}>10</MenuItem>
                                    <MenuItem value={20}>20</MenuItem>
                                    <MenuItem value={50}>50</MenuItem>
                                    <MenuItem value={100}>100</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    )}

                    <Box display="flex" alignItems="center" justifyContent="center" flexWrap="nowrap" padding='0'>
                        <Button onClick={() => handleChangePage(page - 1)} disabled={page === 0} sx={{ color: '#FF5C00' }}>
                            &lt;
                        </Button>
                        {renderPagination()}
                        <Button onClick={() => handleChangePage(page + 1)} disabled={page >= numberOfPages - 1} sx={{ color: '#FF5C00' }}>
                            &gt;
                        </Button>
                    </Box>
                </Box>
                {isSmallScreen ? <>
                        <div className="TransactionsContainer">
                            {paginatedData.map((el, index) => (
                                <div key={index} className="TransactionCard">
                                    <h3 className="TransactionTitle">{el.driver_name}</h3>
                                    <div className="TransactionCardContainer">
                                        <div className="TransactionCardInfo">
                                            <span className="TransactionCardLabel">Idram:</span>
                                            <span>{el.idram}</span>
                                        </div>
                                        <div className="TransactionCardInfo">
                                            <span className="TransactionCardLabel">Գումար:</span>
                                            <span>{el.amount}</span>
                                        </div>
                                        <div className="TransactionCardInfo">
                                            <span className="TransactionCardLabel">Հեռախոս:</span>
                                            <span>{el.driver_phone}</span>
                                        </div>
                                        <div className="TransactionCardInfo">
                                            <span className="TransactionCardLabel">Վիճակ:</span>
                                            <div className={`status`}>{statusName(el.status)}</div>
                                        </div>
                                        <div className="TransactionCardInfo">
                                            <span className="TransactionCardLabel">Ուղարկվել է:</span>
                                            <span>{formatDateString(el.created_at)}</span>
                                        </div>
                                        <div className="TransactionCardInfo">
                                            <span className="TransactionCardLabel">Բալանս:</span>
                                            <span>{el.balance.toFixed(0)}</span>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </> :
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Վարորդ</TableCell>
                                <TableCell>Հեռախոս</TableCell>
                                <TableCell>Իդրամ</TableCell>
                                <TableCell>Գումար</TableCell>
                                <TableCell>Բալանս</TableCell>
                                <TableCell>Կարգավիճակ</TableCell>
                                <TableCell>Կատարվել է</TableCell>
                                <TableCell>Ուղարկող</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {transactions?.results?.map((el, index) => (
                                <TableRow key={index} sx={{
                                    cursor: 'pointer',
                                    backgroundColor: index % 2 === 0 ? 'grey.100' : 'white',
                                    transition: '0.3s all ease-in-out',
                                    '&:hover': {
                                        backgroundColor: 'grey.200',
                                    },
                                }}>
                                    <TableCell>{el.driver_name}</TableCell>
                                    <TableCell>{el.driver_phone}</TableCell>
                                    <TableCell>{el.idram}</TableCell>
                                    <TableCell>{el.amount}</TableCell>
                                    <TableCell>{el.balance.toFixed(0)}</TableCell>
                                    <TableCell>
                                        <div className={`statusBadge ${handleStatus(el.status_code)}`}>
                                            {statusName(el.status_code)}
                                        </div>
                                    </TableCell>
                                    <TableCell>{formatTimestamp(el.created)}</TableCell>
                                    <TableCell>{
                                        el.origin === "Park" ? 'Ավտոմատ' : el.origin === 'driver' ? 'Վարորդ' : 'Ադմին'
                                    }</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>

                }

                {/* Bottom Pagination */}
                <Box display="flex" alignItems="center" justifyContent="space-between" px={2} mt={2}>
                    {!isSmallScreen && ( // Conditionally render this part based on screen size
                        <Box display="flex" alignItems="center">
                            <Typography variant="body1" sx={{ marginRight: 1 }}>Ցուցադրվող քանակ:</Typography>
                            <FormControl variant="outlined" size="small">
                                <Select value={rowsPerPage} onChange={handleChangeRowsPerPage}>
                                    <MenuItem value={10}>10</MenuItem>
                                    <MenuItem value={20}>20</MenuItem>
                                    <MenuItem value={50}>50</MenuItem>
                                    <MenuItem value={100}>100</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    )}
                    <Box display="flex" alignItems="center" justifyContent="center" flexWrap="nowrap">
                        <Button onClick={() => handleChangePage(page - 1)} disabled={page === 0} sx={{ color: '#FF5C00' }}>
                            &lt;
                        </Button>
                        {renderPagination()}
                        <Button onClick={() => handleChangePage(page + 1)} disabled={page >= numberOfPages - 1} sx={{ color: '#FF5C00' }}>
                            &gt;
                        </Button>
                    </Box>
                </Box>
            </TableContainer>
        </>
    );
};

export default AdminOrders;
