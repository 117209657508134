import { createSlice } from "@reduxjs/toolkit";
import {fetchAntiFraud} from "./antiFraudAPI";

const antiFraudSlice = createSlice({
    name: 'antiFraud',
    initialState: {
        data: null
    },
    reducers:{
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAntiFraud.fulfilled, (state, {payload}) => {
                return{
                    ...state,
                    data: payload
                }
            })
    },
});
export const selectAntiFraud = (state) => state.antiFraud.data
export const antiFraudReducer = antiFraudSlice.reducer;
