import { useEffect, useState } from 'react';
import CircularProgress from '../../../sharedComponents/CircularProgres/CircularProgres';
import LiveChart from '../../../sharedComponents/LiveChart/LiveChart';
import './Dashboard.css';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectActiveDriversCount,
    selectOrdersData, selectTransactionsData
} from '../../../store/slices/adminSlices/adminDashboardSlice/adminDashboardSlice';
import {
    fetchActiveDrivers,
    fetchOrderTotals, fetchTransactionTotals
} from '../../../store/slices/adminSlices/adminDashboardSlice/adminDashboardAPI';
import PageHeader from '../PageHeader';
import { fetchParkDetails } from "../../../store/slices/adminSlices/adminLoginSlice/adminLoginAPI";
import { fetchTransactionList } from "../../../store/slices/adminSlices/adminTransactionList/adminTransactionListAPI";
import { fetchAdminOrderList } from "../../../store/slices/adminSlices/adminOrderList/adminOrderAPI";
import {selectAdminTransactionList} from "../../../store/slices/adminSlices/adminTransactionList/adminTransactionList";
import {selectAdminOrderList} from "../../../store/slices/adminSlices/adminOrderList/adminOrderList";
import {useNavigate} from "react-router-dom";
import {selectAdminLogin} from "../../../store/slices/adminSlices/adminLoginSlice/adminLoginSlice";

const Dashboard = () => {
    const dispatch = useDispatch();
    const ordersData = useSelector(selectOrdersData);
    const chartData = ordersData?.daily_sums;
    const navigate = useNavigate();
    const transactionsData = useSelector(selectTransactionsData);
    const transactionCount = transactionsData?.transaction_summary.reduce((sum, item) => sum + item.transaction_count, 0);
    const active_drivers_count = useSelector(selectActiveDriversCount);
    const latestTransactions = useSelector(selectAdminTransactionList);
    const latestOrders = useSelector(selectAdminOrderList);
    const [dashboardFilterValues, setDashboardFilterValues] = useState({
        dateRange: [],
        dateRangeDates: [],
        status: [],
        inputVal: '',
        driver_id: ''
    });

    useEffect(() => {
        dispatch(fetchOrderTotals());
        dispatch(fetchTransactionTotals());
        dispatch(fetchActiveDrivers());
        dispatch(fetchParkDetails());
        dispatch(fetchTransactionList({limit: 3, offset: 0})); // Fetch 3 latest transactions
        dispatch(fetchAdminOrderList({limit: 3, offset: 0})); // Fetch 3 latest orders
    }, [dispatch]);
    const handleStatus = (status) => {
        switch (status) {
            case 'completed':
                return 'statusCompleted'
            case 'complete':
                return 'statusCompleted'
            case 'limit exceeded':
                return 'statusLimit_exceeded'
            case 'pending':
                return 'statusPending'
            case 'not enough balance':
                return 'statusNot_enough_balance'
            default:
                return 'unknownStatus'
        }
    }
    const statusName = (status) => {
        switch (status) {
            case 'completed':
                return 'Փոխանցված'
            case 'complete':
                return 'Ավարտված'
            case 'limit exceeded':
                return 'Սահմանաչափը գերազանցված է'
            case 'pending':
                return 'Ընթացքում'
            case 'not enough balance':
                return 'Անբավարար միջոցներ'
            default:
                return 'Անհայտ խնդիր'
        }
    }
    function formatNumber(number) {
        if (number) {
            return number.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        }
        return 0;
    }

    function graphPercent(x, y) {
        if (x && y) {
            return ((y / x) * 100).toFixed(1);
        }
        return 0;
    }

    return (
        <div className="Dashboard">
            <PageHeader
                heading="Հաշվետվություն"
                page={'dashboard'}
                filterValues={dashboardFilterValues}
                setFilterValues={setDashboardFilterValues}
            />

            <div className="DashboardMainInfo">
                <div className="DashboardBadges">
                    <h3>Կատարված</h3>
                    <span>֏{formatNumber(ordersData?.total_price) || 0}</span>
                    <span className="badgesSecondaryInfo">Քանակ։ {ordersData?.orders_count || 0}</span>
                    <i className="fa-regular fa-circle-check badgesIcons"></i>
                </div>
                <div className="DashboardBadges">
                    <h3>Փոխանցված</h3>
                    <span>֏{formatNumber(transactionsData?.complete_amount) || 0}</span>
                    <span className="badgesSecondaryInfo">Քանակ։ {transactionCount || 0}</span>
                    <i className="fa-solid fa-money-bill-transfer badgesIcons"></i>
                </div>
                <div className="DashboardBadges">
                    <h3>Diamond Pay</h3>
                    <span>֏{formatNumber(transactionsData?.park_percentage) || '--'}</span>
                    <i className="fa-solid fa-percent badgesIcons"></i>
                </div>
                <div className="DashboardBadges">
                    <h3>Ակտիվ վարորդներ</h3>
                    <span className="badgesSecondaryInfo activeDrivers">{active_drivers_count || '--'}</span>
                    <i className="fa-solid fa-bolt badgesIcons"></i>
                </div>
            </div>

                    <LiveChart chartData={chartData}/>

            {/* Latest Transactions Section */}
            <div className="DashboardLatest">
                <div className='DashboardLatestTransactions'>
                    <h3>Վերջին գործարքները</h3>
                    <ul>
                        {latestTransactions?.results?.map((transaction) => {
                            return (
                                <li key={transaction.id}>
                                    <span>{transaction.driver_name}</span>
                                    <span>֏{formatNumber(transaction.amount)}</span>
                                    <span className={`statusBadge ${handleStatus(transaction.status_code)}`}>{statusName(transaction.status_code)}</span>
                                    <span>{new Date(transaction.created_at).toLocaleDateString()}</span>
                                </li>
                            )
                        })}
                    </ul>
                    <button
                        className="TesnelAvelinBtn"
                        onClick={() => navigate('/admin/transactions')}
                    >
                        Տեսնել ավելին
                    </button>
                </div>

                <div className='DashboardLatestOrders'>
                    <h3>Վերջին պատվերները</h3>
                    <ul>
                        {latestOrders?.results?.map((order) => {
                            return (
                                <li key={order.id}>
                                    <span>{order.driver_name}</span>
                                    <span>֏{formatNumber(order.price)}</span>
                                    <span className={`statusBadge ${handleStatus(order.status)}`}>{statusName(order.status)}</span>
                                    <span>{new Date(order.created_at).toLocaleDateString()}</span>
                                </li>
                            )
                        })}
                    </ul>
                    <button
                        className="TesnelAvelinBtn"
                        onClick={() => navigate('/admin/orders')}
                    >
                        Տեսնել ավելին
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;