import React, { useEffect, useState } from 'react';
import './CircularProgres.css';

const CircularProgress = ({ percent = 0, text = 'Փոխանցված', borderR = 'round' }) => {
    const radius = 100; 
    const circumference = 2 * Math.PI * radius; 
    const [currentOffset, setCurrentOffset] = useState(circumference); // Initialize at full circumference

    useEffect(() => {
        const endOffset = circumference - (percent / 100) * circumference;
        setCurrentOffset(endOffset);
    }, [percent, circumference]);

    return (
        <div className="CircularProgress">
            <svg width="250" height="250" viewBox="0 0 250 250">
                <circle
                    cx="125"
                    cy="125"
                    r={radius}
                    strokeWidth="15"
                    style={{
                        stroke: '#e6e6e6',
                        fill: 'transparent',
                    }}
                />
                <circle
                    cx="125"
                    cy="125"
                    r={radius}
                    strokeWidth="15"
                    style={{
                        stroke: '#FF5C00',
                        fill: 'transparent',
                        strokeDasharray: `${circumference} ${circumference}`,
                        strokeDashoffset: currentOffset,
                        transition: 'stroke-dashoffset 1s linear', // Linear transition
                        strokeLinecap: borderR,
                        transform: 'rotate(-90deg)',
                        transformOrigin: '50% 50%',
                    }}
                />
                <text
                    x="50%"
                    y={text ? '45%' : '50%'}
                    textAnchor="middle"
                    dominantBaseline="middle"
                    fill="#FF5C00"
                    fontSize="40"
                    fontWeight={600}
                >
                    {percent}% {/* Static percentage */}
                </text>
                {text && 
                    <text
                        x="50%"
                        y="65%"
                        textAnchor="middle"
                        dominantBaseline="middle"
                        fill="#FF5C00"
                        fontSize="20"
                        fontWeight={500}
                    >
                        {text}
                    </text>
                }
            </svg>
        </div>
    );
};

export default CircularProgress;
