import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from '../../../axiosInstance';

export const mainLogin = createAsyncThunk(
    'adminLogin/mainLogin',
    async function ({ username, password }) {

        const response = await axios.post(`https://api.diamondpay.am/api/login`, {
            username,
            password
        },
            {
                headers: {
                    'Content-Type': 'application/json',
                }
            });

        const data = response.data;
        return data;
    }
);

//get /api/admin/park/list

export const mainParkList = createAsyncThunk(
    'adminLogin/mainParkList',
    async function () {
        const response = await axios.get(`https://api.diamondpay.am/api/admin/park/list`,
            {
                headers: {
                    'Content-Type': 'application/json',
                }
            });

        const data = response.data;
        return data;
    }
);
const getFirstDayOfCurrentMonth = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const day = '01'; // First day of the month
    return `${year}-${month}-${day}`;
};


export const mainParkReport = createAsyncThunk(
    'adminLogin/mainParkReport',
    async function ({park_id}) {
        const response = await axios.get(`https://api.diamondpay.am/api/park/report`,
            {
                params: {
                    park_id,
                    start: getFirstDayOfCurrentMonth(),
                },
                headers: {
                    'Content-Type': 'application/json',
                }
            });

        const data = response.data;
        data.park_id = park_id
        return data;
    }
);
