import { createSlice } from "@reduxjs/toolkit";
import {fetchActiveDrivers, fetchOrderTotals, fetchTransactionTotals} from "./adminDashboardAPI";

const adminDashboardSlice = createSlice({
    name: 'dashboard',
    initialState: {
        isLoading: false,
        data: null,
        ordersData: null,
        transactionsData: null,
        active_drivers_count: 0,
    },
    reducers:{},
    extraReducers: (builder) => {
        builder
            // .addCase(fetchDashboard.fulfilled, (state, {payload}) => {
            //     return {
            //         ...state,
            //         data: {...payload},
            //         isLoading: false
            //     };
            // })
            // .addCase(fetchDashboard.pending,(state, {payload})=>{
            //     return {
            //         ...state,
            //         isLoading: true
            //     }
            // })
            .addCase(fetchOrderTotals.fulfilled, (state, {payload})=>{
                return {
                    ...state,
                    ordersData: payload
                }
            })
            .addCase(fetchTransactionTotals.fulfilled, (state, {payload})=>{
                return {
                    ...state,
                    transactionsData: payload
                }
            })
            .addCase(fetchActiveDrivers.fulfilled, (state, {payload})=>{
                return {
                    ...state,
                    active_drivers_count: payload.active_drivers_count,
                }
            })
    },
});

export const selectDashboard = (state) => state.dashboard;
export const selectOrdersData = (state) => state.dashboard?.ordersData;
export const selectTransactionsData = (state) => state.dashboard?.transactionsData
export const selectActiveDriversCount = (state) => state.dashboard?.active_drivers_count
export const adminDashboardReducer = adminDashboardSlice.reducer;
